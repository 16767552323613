import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const StudentReportHeader = () => {
  const navigate = useNavigate()

  return (
    <div className="assignment_header_container">
      <div className="assignment_header_heading">
        <FaAngleLeft className="cursor-pointer" onClick={() => navigate(-1)} />
        <h2>Student Report</h2>
      </div>
      <div className="assignment_header_btn"></div>
    </div>
  );
};

export default StudentReportHeader;
