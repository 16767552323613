import React from "react";
import { MdAdd } from "react-icons/md";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const AssignmentHeader = ({ title }) => {
  const navigate = useNavigate()
  return (
    <div className="assignment_header_container">
      <div className="assignment_header_heading">
        <FaAngleLeft className="cursor-pointer" onClick={()=>navigate(-1)} />
        <h2>{title}</h2>
      </div>
      <div className="assignment_header_btn">
        <button onClick={()=>navigate("/work/form")}>
          <MdAdd />
          <span>create Work</span>
        </button>
      </div>
    </div>
  );
};

export default AssignmentHeader;
