import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { asyncCreateModule } from "../../actions/batchApi";
import SmallLoader from "../../utils/SmallLoader";
import { notifyDanger, notifySuccess } from "../../actions/customFn";

const ScheduleModal = ({
  modalShow,
  setShowModal,
  allBatches,
  isEdit,
  data,
  module_id,
  batch_id,
  updateData,
}) => {
  const [step, setStep] = useState(1);
  const [time, setTime] = useState("");
  const [batchId, setBatchId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [moduleList, setModuleList] = useState([]);
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModuleLoading, setModuleIsLoading] = useState(false);
  const [moduleTitle, setModuleTitle] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const stepOneErr = {};
  const [stepOneErrors, steOneErrors] = useState({});

  const stepTwoErr = {};
  const [stepTwoErrors, setStepTwoErrors] = useState({});

  const [timeInUtc, setTimeInUtc] = useState({
    time: "",
    format: "",
  });

  const [isScheduleLoading, setIsScheduleLoading] = useState(false);

  const handleChangeTimeToUtC = (e) => {
    if (e.target.value !== "") {
      setTime(e.target.value);
      const parsedDatetime = moment(e.target.value);
      const utcDatetime = parsedDatetime.utc();
      setTimeInUtc({
        time: utcDatetime.format(),
        format: utcDatetime.format("DD MM  YYYY LT"),
      });
    } else {
      setTime("");
      setTimeInUtc({
        time: "",
        format: "",
      });
    }
  };

  const initialState = () => {
    setShowModal(false);
    setStep(1);
    setModuleId("");
    setBatchId("");
    setTitle("");
    setTime("");
    setDescription("");
    setTimeInUtc({
      time: "",
      format: "",
    });
  };

  useEffect(() => {
    setModuleList([]);
    if (batchId) {
      const url = `/batch/get-batch-byId/${batchId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          setModuleList(data.modules);
        })
        .catch((err) => {
          console.log(err);
          setModuleList([]);
        });
    }
  }, [batchId, isUpdate]);

  const createModule = (e) => {
    const initialState = () => {
      setModuleTitle("");
    };
    e.preventDefault();
    moduleValidation();
    if (Object.keys(stepOneErr).length === 0) {
      setModuleList([]);
      setModuleId("");
      setModuleIsLoading(true);
      const data = {
        id: batchId,
        title: moduleTitle,
      };
      dispatch(
        asyncCreateModule(data, setIsUpdate, setModuleIsLoading, initialState)
      );
    }
  };

  const moduleValidation = () => {
    if (batchId.length === 0) {
      stepOneErr.batch = "Please select batch!";
    }
    steOneErrors(stepOneErr);
  };

  const stepOneValidation = () => {
    if (batchId.length === 0) {
      stepOneErr.batch = "Please select batch!";
    }
    if (moduleId.length === 0) {
      stepOneErr.module = "Please select module!";
    }
    steOneErrors(stepOneErr);
  };

  const handleNextStep = () => {
    stepOneValidation();
    if (Object.keys(stepOneErr).length === 0) {
      setStep(2);
    }
  };

  const CreateSchedule = () => {
    const formData = new FormData();
    formData.append("moduleId", moduleId);
    formData.append("title", title);
    formData.append("content", description);
    formData.append("isLive", true);
    formData.append("schedule_time", timeInUtc.time);

    const url = "/batch/upload-video";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsScheduleLoading(false);
        initialState();
      })
      .catch((err) => {
        console.log(err.response);
        setIsScheduleLoading(false);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };

  const UpdateSchedule = async () => {
    const formData = new FormData();
    formData.append("lessonId", data._id);
    formData.append("title", title);
    formData.append("content", description);
    formData.append("isLive", true);
    formData.append("schedule_time", timeInUtc.time);

    const url = "/batch/update-module-video";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsScheduleLoading(false);
        initialState();
        updateData((p) => !p);
      })
      .catch((err) => {
        console.log(err);
        setIsScheduleLoading(false);
        notifyDanger(err?.response?.message || "Some error Occured");
      });
  };

  const stepTwoValidation = () => {
    if (title.length === 0) {
      stepTwoErr.title = "Title is required";
    }
    if (time.length === 0) {
      stepTwoErr.time = "Time is required";
    }
    if (description.length === 0) {
      stepTwoErr.description = "Description is required";
    }
    setStepTwoErrors(stepTwoErr);
  };

  const handleSchedule = () => {
    stepTwoValidation();
    if (Object.keys(stepTwoErr).length === 0) {
      setIsScheduleLoading(true);
      if (isEdit) {
        UpdateSchedule();
      } else {
        CreateSchedule();
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setModuleId(module_id);
      setBatchId(batch_id);
      setTitle(data.title);
      setDescription(data.content);
      const parsedDatetime = moment(data.schedule_time);
      setTime(parsedDatetime.format("YYYY-MM-DDTHH:mm"));
      const utcDatetime = parsedDatetime.utc();
      setTimeInUtc({
        time: utcDatetime.format(),
        format: utcDatetime.format("DD MM  YYYY LT"),
      });
    }
  }, [data, module_id, batch_id]);

  return (
    <Modal
      centered
      className="create-schedule-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body>
        <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="create-schedule-modal-body">
          <div className="create-schedule-modal-title">
            <h2> {isEdit ? "Update Schedule" : "Create Schedule"}</h2>
          </div>
          {step === 1 && (
            <>
              <div className="create-schedule-modal-inp">
                <label>Select Batch</label>
                <select
                  disabled={isEdit ? true : isModuleLoading}
                  value={batchId}
                  onChange={(e) => setBatchId(e.target.value)}
                >
                  <option value={""}>Select Batch</option>
                  {allBatches?.length > 0 &&
                    allBatches.map((data, i) => {
                      return (
                        <option value={data._id}>{data.batch_name}</option>
                      );
                    })}
                </select>
                {stepOneErrors.batch && (
                  <div className="form_error_div">{stepOneErrors.batch}</div>
                )}
              </div>
              <div className="create-schedule-modal-inp">
                <label>Select Module</label>
                <select
                  value={moduleId}
                  disabled={isModuleLoading}
                  onChange={(e) => setModuleId(e.target.value)}
                >
                  <option value={""}>Select Module</option>
                  {moduleList?.length > 0 &&
                    moduleList.map((data, i) => {
                      return <option value={data._id}>{data.title}</option>;
                    })}
                </select>
                {stepOneErrors.module && (
                  <div className="form_error_div">{stepOneErrors.module}</div>
                )}
              </div>
              <div className="create-schedule-modal-or">
                <span></span>
                <b>OR</b>
                <span></span>
              </div>
              <form onSubmit={createModule}>
                <div className="create-schedule-modal-groupinp">
                  <input
                    placeholder="Enter Module Name"
                    type="text"
                    required
                    value={moduleTitle}
                    onChange={(e) => setModuleTitle(e.target.value)}
                  />
                  <button disabled={isModuleLoading}>
                    {isModuleLoading ? (
                      <SmallLoader color={"#fff"} />
                    ) : (
                      "Create Module"
                    )}{" "}
                  </button>
                </div>
              </form>

              <div className="create-schedule-modal-submit">
                <button
                  disabled={isModuleLoading}
                  onClick={() => handleNextStep()}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="create-schedule-modal-inp">
                <label>Title of Your Video</label>
                <input
                  disabled={isScheduleLoading}
                  type="text"
                  placeholder="Try Some Relevant Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {stepTwoErrors.title && (
                  <div className="form_error_div">{stepTwoErrors.title} </div>
                )}
              </div>
              <div className="create-schedule-modal-inp">
                <label>Description of Your Video</label>
                <textarea
                  disabled={isScheduleLoading}
                  placeholder="Enter video description"
                  rows={"3"}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {stepTwoErrors.description && (
                  <div className="form_error_div">
                    {stepTwoErrors.description}{" "}
                  </div>
                )}
              </div>
              <div className="create-schedule-modal-inp">
                <label>Enter Schedule time </label>
                <input
                  disabled={isScheduleLoading}
                  type="datetime-local"
                  placeholder="Try Some Relevant Title"
                  value={time}
                  onChange={(e) => {
                    handleChangeTimeToUtC(e);
                  }}
                />
                {stepTwoErrors.time && (
                  <div className="form_error_div">{stepTwoErrors.time} </div>
                )}
              </div>
              {timeInUtc.time !== "" && (
                <h6 className="mt-1 mb-3">
                  Schedule time will be: {timeInUtc.format} (UTC){" "}
                </h6>
              )}
              <div className="create-schedule-modal-submit">
                <button disabled={isScheduleLoading} onClick={() => setStep(1)}>
                  Previous
                </button>
                <button disabled={isScheduleLoading} onClick={handleSchedule}>
                  {isScheduleLoading ? <SmallLoader color={"#fff"} /> : "Done"}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleModal;
