import React, { createContext, useContext, useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import AssignmentHeader from "../components/assignment/AssignmentHeader";
import AssignmentviewTable from "../components/assignment/AssignmentviewTable";
import axios from "../api/axios";
import { useParams } from "react-router-dom";
import Loader from "../utils/Loader";

export const AssignmentViewContext = createContext();

const AssignmentView = () => {
  const [assignment, setAssignment] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = `/batch/assignment/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAssignment(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [isUpdate]);

  return (
    <AssignmentViewContext.Provider value={{ setIsLoading, setIsUpdate }}>
      <LayoutWrapper>
        <Header />
        <AssignmentHeader title={assignment.title ? assignment.title : ""} />
        {isLoading ? (
          <Loader Height={"55vh"} />
        ) : (
          <AssignmentviewTable assignment={assignment} />
        )}
      </LayoutWrapper>
    </AssignmentViewContext.Provider>
  );
};

export default AssignmentView;
