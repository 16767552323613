import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { images } from "../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";

const ModuleHeader = ({batch , id}) => {
  const navigate = useNavigate()
  return (
    <div className="module_header_container">
      <div className="module_header_title">
        <FaAngleLeft className="cursor-pointer" onClick={()=>navigate(-1)} />
        <img alt="" src={batch.img} />
        <h2>{batch.name}</h2>
      </div>
      <div className="module_header_navs">
        <NavLink to={`/batch/module/${id}`}>Module</NavLink>
        <NavLink to={`/batch/students/${id}`}>Students</NavLink>
        <NavLink to={`/batch/schedule/${id}`}>schedule</NavLink>
      </div>
    </div>
  );
};

export default ModuleHeader;
