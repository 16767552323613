import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notificationData",
  initialState: {
    notification: 0,
    video_notification: {
      title: "",
      uploadedpercent: 0,
      estimated_time_in_min: 0,
      estimated_time_in_sec: 0,
    },
    isNotification: false,
  },
  reducers: {
    setVideoNotification: (state, action) => {
      state.video_notification = action.payload;
    },
    setNotificationOpen: (state, action) => {
      state.isNotification = action.payload;
    },
  },
});

export const { setVideoNotification, setNotificationOpen } =
  notificationSlice.actions;

export default notificationSlice.reducer;
