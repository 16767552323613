import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import AssignmentHeader from "../components/assignment/AssignmentHeader";
import AnnouncementHeader from "../components/announcement/AnnouncementHeader";
import AnnouncementList from "../components/announcement/AnnouncementList";

const Announcement = () => {
  return (
    <LayoutWrapper>
      <Header />
      <AnnouncementHeader />
      <AnnouncementList />
    </LayoutWrapper>
  );
};

export default Announcement;
