import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import Select from "react-select";
import axios from "../../api/axios";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import SmallLoader from "../../utils/SmallLoader";

const AnnouncementModal = ({ modalShow, setShowModal, setIsUpdate }) => {
  const [allBatches, setAllBatches] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);
  const [description, setDescription] = useState("");
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      setAllBatches(profileData.batches);
      const batches = profileData.batches.map((data) => {
        return {
          label: data.batch_name,
          value: data._id,
        };
      });

      setAllBatches(batches);
    }
  }, [profileData]);

  const initialState = () => {
    setSelectedBatches([]);
    setDescription("");
  };

  const createAnnouncement = () => {
    setIsLoading(true);
    const url = "/notification/create-announcement";
    // recipientArr, announcement, announcer
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const newArr = [];
    for (let i = 0; i < profileData.batches.length; i++) {
      if (
        selectedBatches.find(
          (data) => data.value === profileData.batches[i]._id
        )
      ) {
        newArr.push(profileData.batches[i].enroll_students);
      }
    }
    const arraySpread = [].concat(...newArr);
    const recipientArr = arraySpread.map((data) => {
      return data._id;
    });

    const data = {
      announcer: profileData._id,
      announcement: description,
      recipientArr,
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsLoading(false);
        setIsUpdate((p) => !p);
        setShowModal(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.data.message || "Some Errror Occured!");
      });
  };

  return (
    <Modal
      show={modalShow}
      className="bootstrap-modal-custom "
      onHide={() => {
        setShowModal(false);
      }}
      centered
    >
      <Modal.Body>
        <div className="announcement-modal-body">
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <h2>Make an Announcement</h2>
          <div className="announcement-modal-inp">
            <Select
              isMulti
              name="colors"
              options={allBatches}
              onChange={(e) => setSelectedBatches(e)}
              className="basic-multi-select"
              value={selectedBatches}
              classNamePrefix="select"
              placeholder="Select Batch"
            />
            <textarea
              placeholder="Post an announcement"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="announcement-modal-btn">
            <button disabled={isLoading} onClick={() => createAnnouncement()}>
              {isLoading ? <SmallLoader color={"#fff"} /> : "Post"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AnnouncementModal;
