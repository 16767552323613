import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose } from "react-icons/ai";
import { FaAngleLeft } from "react-icons/fa6";
import { MdAdd, MdSmartDisplay } from "react-icons/md";
import {
  confirmToast,
  getShortString,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import axios from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../utils/fileUpload";
import Loader from "../../utils/Loader";
import { FaFileAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import SmallLoader from "../../utils/SmallLoader";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { asyncSendNotification } from "../../actions/notificationApi";

const WorkForm = () => {
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileArr, setFileArr] = useState([]);
  const [allBatches, setAllBatches] = useState([]);

  const { id } = useParams();

  const [assignmentData, setAssignmentData] = useState({
    title: "",
    description: "",
    instruction: "",
    deadline: "",
    priority: "",
    batchId: "",
    type: "",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate()

  const [uploadedFiles, setUploadFiles] = useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  const profileData = useSelector((state) => state.profileData.userData);

  const handleDataChange = (e) => {
    const { value, name } = e.target;
    setAssignmentData({ ...assignmentData, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileArr([...fileArr, e.target.files[0]]);
    }
  };

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileArr([...fileArr, acceptedFiles[0]]);
  };
  const filterFile = (ID) => {
    const filterArr = fileArr.filter((val, i) => ID !== i);
    setFileArr(filterArr);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: {
    //   "video/*": [],
    // },
  });

  const getFileExtension = (filename) => {
    const parts = filename.split(".");
    return parts[parts.length - 1].toLowerCase();
  };

  const initialState = () => {
    setAssignmentData({
      title: "",
      description: "",
      instruction: "",
      deadline: "",
      priority: "",
      batchId: "",
      type: "",
    });
    setFileArr([]);
  };

  const submitAssignment = async () => {
    setIsLoading(true);
    const url = "/batch/assignment-create";
    const data = {
      title: assignmentData.title,
      description: assignmentData.description,
      instruction: assignmentData.instruction,
      deadline: assignmentData.deadline,
      priority: assignmentData.priority,
      batchId: assignmentData.batchId,
      work_type: assignmentData.type,
      assignee: profileData._id,
    };

    const enrollStudents = allBatches.find(
      (batch) => batch._id === data.batchId
    );

    const newArr = [];
    if (fileArr.length > 0) {
      for (let i = 0; i < fileArr.length; i++) {
        let extension = getFileExtension(fileArr[i].name);
        let fileUrl = await uploadFile(fileArr[i], extension, "", false);
        newArr.push({
          url: fileUrl,
          format: extension,
          name: fileArr[i].name,
        });
      }
    }
    data.resourceArr = newArr;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setIsLoading(false);
        notifySuccess(message);
        initialState();
        console.log(enrollStudents);
        const studentIds = enrollStudents.enroll_students.map((doc) => {
          return doc._id;
        });
        const data = {
          recipientArr: studentIds,
          messages: {
            notification_msg: `${assignmentData.type} has been assigned to you!`,
            date: new Date(),
            notification_type: "work",
          },
        };
        dispatch(asyncSendNotification(data));
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  useEffect(() => {
    if (Object.keys(profileData).length > 0) setAllBatches(profileData.batches);
  }, [profileData]);

  const handleUploadFile = (e) => {
    e.preventDefault();
    if (id) {
      updateAssignment();
    } else {
      submitAssignment();
    }
  };

  useEffect(() => {
    if (id) {
      const url = `/batch/assignment/${id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          setAssignmentData({
            title: data.title,
            description: data.description,
            instruction: data.instruction,
            deadline: moment(data.deadline).format("YYYY-MM-DD"),
            priority: data.priority,
            batchId: data.batchId._id,
            type: data.work_type,
          });
          setUploadFiles(data.resources);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, isUpdate]);

  const updateAssignment = async () => {
    setIsLoading(true);
    const url = "/batch/assignment-update";
    const data = {
      title: assignmentData.title,
      description: assignmentData.description,
      instruction: assignmentData.instruction,
      deadline: assignmentData.deadline,
      priority: assignmentData.priority,
      batchId: assignmentData.batchId,
      assignee: profileData._id,
      id: id,
      work_type: assignmentData.type,
    };

    const newArr = [];
    if (fileArr.length > 0) {
      for (let i = 0; i < fileArr.length; i++) {
        let extension = getFileExtension(fileArr[i].name);
        let fileUrl = await uploadFile(fileArr[i], extension, "", false);
        newArr.push({
          url: fileUrl,
          format: extension,
          name: fileArr[i].name,
        });
      }
    }

    data.resourceArr = newArr;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((p) => !p);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  const deleteResources = (ID) => {
    const url = `/batch/assignment/resource-delete/${ID}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        setIsLoading(false);
        setIsUpdate((p) => !p);
        notifySuccess(message);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  return (
    <div className="work_form_container">
      <form onSubmit={handleUploadFile}>
        <div className="assignment_header_container">
          <div className="assignment_header_heading">
            <FaAngleLeft className="cursor-pointer" onClick={()=>navigate(-1)} />
            <h2>Work</h2>
          </div>
          <div className="assignment_header_btn">
            <button disabled={isLoading}>
              <span>
                {" "}
                {isLoading ? <SmallLoader color={"#fff"} /> : "Upload"}
              </span>
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loader Height={"45vh"} />
        ) : (
          <div className="work_form_div">
            <div className="row work_form_div_row scrollbar-template">
              <div className="col-12">
                <div className="work_form_inp">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="Try Some Relevant Title"
                    value={assignmentData.title}
                    onChange={handleDataChange}
                    name="title"
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="work_form_inp">
                  <label>Description </label>
                  <textarea
                    rows={"3"}
                    placeholder="Enter Assignment Description"
                    value={assignmentData.description}
                    onChange={handleDataChange}
                    name="description"
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="work_form_inp">
                  <label>Instruction </label>
                  <textarea
                    rows={"3"}
                    placeholder="Enter Assignment Instruction"
                    value={assignmentData.instruction}
                    onChange={handleDataChange}
                    name="instruction"
                    required
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="work_form_inp">
                  <label>Type</label>
                  <select
                    value={assignmentData.type}
                    onChange={handleDataChange}
                    name="type"
                    required
                  >
                    <option value={""}>Select Type</option>
                    <option value={"assignment"}>Assignment</option>
                    <option value={"project"}>Project</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="work_form_inp">
                  <label>Batch </label>
                  <select
                    value={assignmentData.batchId}
                    onChange={handleDataChange}
                    name="batchId"
                    required
                  >
                    <option value={""}>Select Batch</option>
                    {allBatches.map((data, i) => {
                      return (
                        <option key={i} value={data._id}>
                          {data.batch_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="work_form_inp">
                  <label>Due Date </label>
                  <input
                    type="date"
                    placeholder="Try Some Relevant Title"
                    value={assignmentData.deadline}
                    onChange={handleDataChange}
                    name="deadline"
                    required
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="work_form_inp">
                  <label>Priority </label>
                  <select
                    value={assignmentData.priority}
                    onChange={handleDataChange}
                    name="priority"
                    required
                  >
                    <option value={""}>Select Priority</option>
                    <option>Intermediate</option>
                    <option>High</option>
                    <option>Medium</option>
                  </select>
                </div>
              </div>

              <div className="col-12">
                <div className="work_form_inp">
                  <div className="upload-video-modal-inp">
                    <label>Upload File</label>
                    <div
                      {...getRootProps()}
                      className={`dropzone  ${isDragActive ? "active" : ""}`}
                    >
                      <input {...getInputProps()} />
                      <span>Browse Your Device</span>
                      <p>or</p>
                      <p>Drag & Drop here</p>
                    </div>
                    {/* <div className="upload-video-modal-or">
                  <span></span>
                  <b>Or</b>
                  <span></span>
                </div>
                <input placeholder="Paste the Video URL " type="text" /> */}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  {fileArr.length > 0 &&
                    fileArr.map((data, i) => {
                      return (
                        <div className="col-4">
                          <div className="work_form_item">
                            <FaFileAlt className="work_form_item_file" />
                            <span>
                              {getShortString(data.name, 18)}.
                              {getFileExtension(data.name)}
                            </span>
                            <IoMdClose
                              className="work_form_item_close"
                              onClick={() => filterFile(i)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {uploadedFiles.length > 0 && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>Uploaded Files</h6>
                    </div>
                    {uploadedFiles.map((data, i) => {
                      return (
                        <div className="col-4">
                          <div className="work_form_item">
                            <FaFileAlt className="work_form_item_file" />
                            <span>
                              {getShortString(data.name || data.url, 18)}.
                              {getFileExtension(data.url)}
                            </span>
                            <RiDeleteBin5Fill
                              className="work_form_item_close"
                              onClick={() => {
                                confirmToast(
                                  "Are you want to delete this resource?",
                                  () => {
                                    setIsLoading(true);
                                    deleteResources(data._id);
                                  }
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default WorkForm;
