import React, { useContext, useState } from "react";
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import { MdOutlineAdd } from "react-icons/md";
import CreateModuleModal from "./createModuleModal";
import moment from "moment";
import { RiDeleteBin5Fill } from "react-icons/ri";
import axios from "../../api/axios";
import { ModuleContext } from "../../pages/Module";
import { useNavigate } from "react-router-dom";

const DUMMY_DATA = [
  {
    image: images["batch_img_eg.png"],
    title: "Machine Learning",
    date: "15 Jan 2024",
    videonum: 10,
    resourceenum: 3,
  },
  {
    image: images["batch_img_eg.png"],
    title: "DSA",
    date: "21 Jan 2024",
    videonum: 4,
    resourceenum: 2,
  },
];
const ModuleCards = ({ moduleList }) => {
  const [modalShow, setShowModal] = useState(false);

  return (
    <div className="module_card_container">
      <div className="row scrollbar-template">
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12  col-12 ">
          <div className="module_card_item module_card_create">
            <img src={images["module_placeholder.png"]} alt="" />
            <h4>Module Name</h4>
            <h5>Starting Date</h5>
            <h6>No. of videos & Resources</h6>
            <button onClick={() => setShowModal(true)}>
              <MdOutlineAdd />
              <span>create modules</span>
            </button>
          </div>
        </div>
        {moduleList.length > 0 &&
          moduleList.map((data, i) => {
            return <ModuleCardItem data={data} i={i} />;
          })}
      </div>
      <CreateModuleModal modalShow={modalShow} setShowModal={setShowModal} />
    </div>
  );
};

export default ModuleCards;

const ModuleCardItem = ({ data, i }) => {
  const { setIsUpdate, setIsLoading } = useContext(ModuleContext);
  const navigate = useNavigate();
  const handleDeleteModule = () => {
    setIsLoading(true);
    const url = `/batch/module-delete/${data._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err.response);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };
  return (
    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12  col-12 " key={i}>
      <div className="module_card_item">
        <img src={images["forder-img.png"]} alt="" />
        <h4>{data.title}</h4>
        <h5>Started At {moment(data.createdAt).format("DD/MMM/YYYY")}</h5>
        <h6>
          {data?.lessons?.length || 0} videos &{" "}
          {data.refrence_links.length + data.study_material.length || 0}{" "}
          resources
        </h6>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: "10px " }}
        >
          <button onClick={() => navigate(`/batch/module/view/${data._id}`)}>
            View
          </button>
          <RiDeleteBin5Fill
            onClick={() => {
              confirmToast(
                "Are you want to delete this module?",
                handleDeleteModule
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
