import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import CertificateModal from "./CertificateModal";
import { asyncsendCertificate } from "../../actions/batchApi";

const DUMMY_DATA = [
  {
    image: images["img-avatar.png"],
    name: "Test User",
    email: "test@example.com",
    phone: "+92 1234567890",
  },
  {
    image: images["img-avatar.png"],
    name: "Test User 2",
    email: "test2@example.com",
    phone: "+92 1234567890",
  },
  {
    image: images["img-avatar.png"],
    name: "Test User 3",
    email: "test3@example.com",
    phone: "+92 1234567890",
  },
];

const ModuleStudent = ({ id }) => {
  const [batchStudents, setBatchStudents] = useState([]);

  const [courseInfo, setCourseInfo] = useState({
    program: "",
    startDate: "",
  });

  const [isUpdate, setIsUpdate] = useState(false);

  const [certificateData, setCertificateData] = useState({});
  const { pathname } = useLocation();

  const getCertificateData = () => {
    const url = `/batch/certificate-data/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setCertificateData(data);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  const getBatchStudents = () => {
    const splitPath = pathname.split("/")[2];
    const url = `batch/batch-students/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        if (splitPath === "students") {
          setBatchStudents(data?.enroll_students || []);
        } else if (splitPath === "students-active") {
          const filterArr = data?.enroll_students.filter(
            (data) => data.active === true
          );
          setBatchStudents(filterArr);
        } else if (splitPath === "students-deactive") {
          const filterArr = data?.enroll_students.filter(
            (data) => data.active === false
          );
          setBatchStudents(filterArr);
        }
        setCourseInfo({
          program: data.courseInfo.title,
          startDate: data.batch_start,
        });
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "some error occured");
      });
  };

  useEffect(() => {
    getBatchStudents();
    getCertificateData();
  }, [id, isUpdate, pathname]);

  return (
    <div className="module_student_container">
      <div className="module_student_navs">
        <NavLink to={`/batch/students/${id}`}>All</NavLink>
        <NavLink to={`/batch/students-active/${id}`}>Active</NavLink>
        <NavLink to={`/batch/students-deactive/${id}`}>Deactive</NavLink>
      </div>
      <div className="module_student_table scrollbar-template">
        {batchStudents.length > 0 ? (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Profile</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone No.</th>
                <th>Active/Deactive student</th>
                <th>Certificate</th>
              </tr>
            </thead>
            <tbody>
              {batchStudents.map((data, i) => {
                return (
                  <ModuleStudentItem
                    data={data}
                    index={i}
                    courseInfo={courseInfo}
                    certificateData={certificateData}
                    batchId={id}
                    setIsUpdate={setIsUpdate}
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <h4 className="text-center text-black mt-4">No Students Found!</h4>
        )}
      </div>
    </div>
  );
};

export default ModuleStudent;

const ModuleStudentItem = ({
  data,
  index,
  courseInfo,
  certificateData,
  batchId,
  setIsUpdate,
}) => {
  const [modalShow, setShowModal] = useState(false);

  const [currentStudentDetails, setCurrentStudentDetails] = useState(null);
  // const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (certificateData && certificateData?.student_details?.length > 0) {
      const isExist = certificateData?.student_details.find((val) => {
        return val.user === data._id;
      });
      setCurrentStudentDetails(isExist ? true : false);
    }
  }, [certificateData]);

  const handleButton = () => {
    const formData = {
      id: data._id,
      active: !data.active,
    };
    const url = `batch/active-students`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        notifyDanger(err.response.message || "Some error Occured");
      });
  };

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <img alt="" src={data.image} />
      </td>
      <td>{data.username}</td>
      <td>{data.email}</td>
      <td>{data.phone_no}</td>
      <td>
        <div className="module_student_active_deactive">
          <button
            onClick={() => {
              confirmToast("Are you want to deactive this student ?", () => {
                handleButton(); // Add parentheses to call the function
              });
            }}
            className={`${data.active === false && "deactive"}`}
          >
            Deactive
          </button>
          <button
            onClick={() => {
              confirmToast("Are you want to active this student ?", () => {
                handleButton(); // Add parentheses to call the function
              });
            }}
            className={`${data.active === true && "active"}`}
          >
            Active
          </button>
        </div>
      </td>
      <td>
        <button
          disabled={currentStudentDetails}
          className={`${
            currentStudentDetails ? "disabled_certificatebtn" : ""
          }`}
          onClick={() => setShowModal(true)}
        >
          Send Certificate
        </button>
      </td>

      {modalShow && (
        <CertificateModal
          modalShow={modalShow}
          setShowModal={setShowModal}
          data={data}
          courseInfo={courseInfo}
          certificateData={certificateData}
          batchId={batchId}
          setIsUpdate={setIsUpdate}
        />
      )}
    </tr>
  );
};
