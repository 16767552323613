import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ProfileForm from "../components/editprofile/ProfileForm";

const EditProfile = () => {
  return (
    <LayoutWrapper>
      <Header />
      <ProfileForm />
    </LayoutWrapper>
  );
};

export default EditProfile;
