import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ModuleViewHeader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="module_view_header_container">
      <div className="view_header_title">
        <FaAngleLeft className="cursor-pointer" onClick={() => navigate(-1)} />
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default ModuleViewHeader;
