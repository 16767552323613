import AWS from "aws-sdk";
import cryptoRandomString from "crypto-random-string";
import { notifyDanger, notifySuccess } from "../actions/customFn";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  region: "us-east-1",
  useAccelerateEndpoint: true,
});

function generateUniqueKeyName(ext) {
  const timestamp = Date.now();
  const randomString = cryptoRandomString({ length: 10 });
  const uniqueKey = `${timestamp}_${randomString}.${ext}`;
  return uniqueKey;
}

export let isUploadingStatus = false;
export const uploadFile = async (
  file,
  ext,
  title,
  video,
  dispatch,
  setVideoNotification
) => {
  try {
    if (isUploadingStatus) {
      return; // Return immediately if upload is in progress
    }
    isUploadingStatus = true;
    const uniqueKey = generateUniqueKeyName(ext);
    const uploadParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: uniqueKey,
      ContentType: file.type,
      Body: file,
      ACL: "public-read",
    };

    const upload = new AWS.S3.ManagedUpload({
      params: uploadParams,
      partSize: 5 * 1024 * 1024, // 5 MB parts (adjust as needed)
      queueSize: 1, // Number of parallel uploads (adjust as needed)
    });

    let startTime = Date.now();
    let prevUploadedBytes = 0;

    upload.on("httpUploadProgress", (progressData) => {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTime) / 1000; // elapsed time in seconds
      const uploadedBytes = progressData.loaded;
      const totalBytes = progressData.total;

      const speed = (uploadedBytes - prevUploadedBytes) / elapsedTime; // upload speed in bytes per second
      prevUploadedBytes = uploadedBytes;

      const percentUploaded = (uploadedBytes / totalBytes) * 100;
      if (video) {
        dispatch(
          setVideoNotification({
            title,
            uploadedpercent: percentUploaded.toFixed(),
            estimated_time_in_min: 0,
            estimated_time_in_sec: 0,
          })
        );
      }

      const remainingBytes = totalBytes - uploadedBytes;
      let estimatedTime;
      if (video && speed > 0) {
        estimatedTime = remainingBytes / speed; // estimated time in seconds
        if (estimatedTime < 60) {
          estimatedTime = Math.ceil(estimatedTime); // Round up to the nearest second
          // console.log("Estimated Time (seconds):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentUploaded.toFixed(),
              estimated_time_in_min: 0,
              estimated_time_in_sec: estimatedTime,
            })
          );
        } else {
          estimatedTime = Math.ceil(estimatedTime / 60); // Convert to minutes
          // console.log("Estimated Time (minutes):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentUploaded.toFixed(),
              estimated_time_in_min: (estimatedTime / 100).toFixed(0),
              estimated_time_in_sec: 0,
            })
          );
        }
      }
    });

    const data = await upload.promise();

    let videoUrl = data.Location;
    notifySuccess("File uploaded successfully!");
    isUploadingStatus = false;
    if (video) {
      dispatch(
        setVideoNotification({
          title: "",
          uploadedpercent: 0,
          estimated_time_in_min: 0,
          estimated_time_in_sec: 0,
        })
      );
    }
    return videoUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    notifyDanger("File cannot be uploaded!");
    throw new Error("Cannot upload file");
  }
};
