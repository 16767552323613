import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ModuleViewHeader from "../components/moduleview/ModuleViewHeader";
import ModuleVideoview from "../components/moduleVideos/ModuleVideoview";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetModuleDetails } from "../actions/batchApi";

const VideoView = () => {
  const [moduleTitle, setModuleTitle] = useState("");
  const [allVideos, setAllVideos] = useState([]);
  const [currentVideo, setCurrrentVideo] = useState({});

  const { id, urlId } = useParams();
  const dispatch = useDispatch();
  const module_details = useSelector((state) => state.batchData.module_details);

  useEffect(() => {
    dispatch(asyncGetModuleDetails(id));
  }, [id ]);

  useEffect(() => {
    if (module_details && Object.keys(module_details).length > 0) {
      setModuleTitle(module_details.title);
      setAllVideos(module_details.lessons);
      const currentdata = module_details.lessons.find(
        (data) => data._id === urlId
      );
      setCurrrentVideo(currentdata);
    }
  }, [module_details , urlId]);

  return (
    <LayoutWrapper>
      <Header />
      <ModuleViewHeader title={moduleTitle} />
      <ModuleVideoview currentVideo={currentVideo} allVideos={allVideos} id={id} />
    </LayoutWrapper>
  );
};

export default VideoView;
