import Announcement from "./pages/Announcement";
import Assignment from "./pages/Assignment";
import AssignmentForm from "./pages/AssignmentForm";
import AssignmentView from "./pages/AssignmentView";
import Batch from "./pages/Batch";
import EditProfile from "./pages/EditProfile";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Module from "./pages/Module";
import ModuleResources from "./pages/ModuleResources";
import ModuleVideo from "./pages/ModuleVideo";
import ModuleView from "./pages/ModuleView";
import QnaList from "./pages/QnaList";
import StudentReport from "./pages/StudentReport";
import VideoView from "./pages/VideoView";

const HomeComponent = () => {
  if (localStorage.getItem("oeson_auth")) {
    return <Home />;
  } else {
    return <Login />;
  }
};

const RoutesArr = [
  {
    name: "Home",
    path: "/",
    key: "Home",
    route: "/",
    page: <HomeComponent />,
    private: true,
  },
  {
    name: "Batch",
    path: "/batch",
    key: "Batch",
    route: "/batch",
    page: <Batch />,
    private: true,
  },
  {
    name: "BatchActive",
    path: "/batch-active",
    key: "BatchActive",
    route: "/batch-active",
    page: <Batch />,
    private: true,
  },
  {
    name: "BatchInactive",
    path: "/batch-inactive",
    key: "BatchInactive",
    route: "/batch-inactive",
    page: <Batch />,
    private: true,
  },
  {
    name: "Module",
    path: "/batch/module/:id",
    key: "Module",
    route: "/batch/module/:id",
    page: <Module />,
    private: true,
  },
  {
    name: "ModuleStudents",
    path: "/batch/students/:id",
    key: "ModuleStudents",
    route: "/batch/students/:id",
    page: <Module />,
    private: true,
  },
  {
    name: "ModuleStudentsActive",
    path: "/batch/students-active/:id",
    key: "ModuleStudentsActive",
    route: "/batch/students-active/:id",
    page: <Module />,
    private: true,
  },
  {
    name: "ModuleStudentsDeActive",
    path: "/batch/students-deactive/:id",
    key: "ModuleStudentsDeActive",
    route: "/batch/students-deactive/:id",
    page: <Module />,
    private: true,
  },
  {
    name: "ModuleSchedule",
    path: "/batch/schedule/:id",
    key: "ModuleSchedule",
    route: "/batch/schedule/:id",
    page: <Module />,
    private: true,
  },
  {
    name: "ModuleView",
    path: "/batch/module/view/:id",
    key: "ModuleView",
    route: "/batch/module/view/:id",
    page: <ModuleView />,
    private: true,
  },
  {
    name: "ModuleVideo",
    path: "/batch/module/video/:id",
    key: "ModuleVideo",
    route: "/batch/module/video/:id",
    page: <ModuleVideo />,
    private: true,
  },
  {
    name: "VideoView",
    path: "/batch/module/video/view/:id/:urlId",
    key: "VideoView",
    route: "/batch/module/video/view/:id/:urlId",
    page: <VideoView />,
    private: true,
  },
  {
    name: "StudyMaterial",
    path: "/batch/module/study-material/:id",
    key: "StudyMaterial",
    route: "/batch/module/study-material/:id",
    page: <ModuleResources />,
    private: true,
  },
  {
    name: "Work",
    path: "/work",
    key: "Work",
    route: "/work",
    page: <Assignment />,
    private: true,
  },
  {
    name: "workView",
    path: "/work/view/:id",
    key: "workView",
    route: "/work/view/:id",
    page: <AssignmentView />,
    private: true,
  },
  {
    name: "workForm",
    path: "/work/form",
    key: "workForm",
    route: "/work/form",
    page: <AssignmentForm />,
    private: true,
  },
  {
    name: "workForm",
    path: "/work/form/:id",
    key: "workForm",
    route: "/work/form/:id",
    page: <AssignmentForm />,
    private: true,
  },
  {
    name: "Profile",
    path: "/profile",
    key: "Profile",
    route: "/profile",
    page: <EditProfile />,
    private: true,
  },
  {
    name: "QnA",
    path: "/qna",
    key: "QnA",
    route: "/qna",
    page: <QnaList />,
    private: true,
  },
  {
    name: "QnAnswered",
    path: "/qna-answered",
    key: "QnA",
    route: "/qna-answered",
    page: <QnaList />,
    private: true,
  },
  {
    name: "QnAnswered",
    path: "/qna-unanswered",
    key: "QnA",
    route: "/qna-unanswered",
    page: <QnaList />,
    private: true,
  },
  {
    name: "Login",
    path: "/login",
    key: "Login",
    route: "/login",
    page: <Login />,
  },
  {
    name: "Announcement",
    path: "/announcement",
    key: "Announcement",
    route: "/announcement",
    page: <Announcement />,
  },
  {
    name: "student-report",
    path: "/student-report",
    key: "student-report",
    route: "/student-report",
    page: <StudentReport />,
  },
];

export default RoutesArr;
