import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { asyncSendNotification } from "../../actions/notificationApi";

const CommentModal = ({
  modalShow,
  setShowModal,
  data,
  setIsLoading,
  setIsUpdate,
  work_type,
  title,
}) => {
  const [note, setNote] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();

  const handleNoteSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `/batch/assignment/note/`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const formData = { id, userId: data?.user?._id || null, note };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        setShowModal(false);
        notifySuccess(message);
        setIsUpdate((p) => !p);
        const formData = {
          recipientArr: [data?.user?._id],
          messages: {
            notification_msg: `
            Note from Mentor: Review feedback on <b>${title}</b> in ${work_type}.`,
            date: new Date(),
            notification_type: "work",
          },
        };
        dispatch(asyncSendNotification(formData));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  useEffect(() => {
    if (data.note) {
      setNote(data.note);
    }
  }, [data]);

  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body>
        <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="comment-modal-body">
          <div className="comment-modal-body-heading">
            <h2>Note</h2>
          </div>
          <form onSubmit={handleNoteSubmit}>
            <div className="comment-modal-body-inp">
              <label>Type Note</label>
              <textarea
                rows={"3"}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                required
              />
            </div>
            <div className="comment-modal-body-submit">
              <button>Send Note</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommentModal;
