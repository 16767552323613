import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MinusIconCircle, PlusIconCircle } from "../../asset/icons/Icon";
import { images, notifySuccess } from "../../actions/customFn";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api/axios";
import moment from "moment";
import Loader from "../../utils/Loader";
import { asyncSendNotification } from "../../actions/notificationApi";

const DUmmyData = [
  {
    question: "What services does DataPulse provide?",
    answer:
      "What services does DataPulse provide? DataPulse specializes in Data Analytics, Business Intelligence, Website Development, and Mobile Development. We offer comprehensive solutions to harness the power of data for strategic decision-making and digital innovation.",
  },
  {
    question: "What services does DataPulse provide?",
    answer:
      "What services does DataPulse provide? DataPulse specializes in Data Analytics, Business Intelligence, Website Development, and Mobile Development. We offer comprehensive solutions to harness the power of data for strategic decision-making and digital innovation.",
  },
  {
    question: "What services does DataPulse provide?",
    answer:
      "What services does DataPulse provide? DataPulse specializes in Data Analytics, Business Intelligence, Website Development, and Mobile Development. We offer comprehensive solutions to harness the power of data for strategic decision-making and digital innovation.",
  },
  {
    question: "What services does DataPulse provide?",
    answer:
      "What services does DataPulse provide? DataPulse specializes in Data Analytics, Business Intelligence, Website Development, and Mobile Development. We offer comprehensive solutions to harness the power of data for strategic decision-making and digital innovation.",
  },
];

const QnaAccordian = ({
  qnaList,
  batchName,
  selectedBatch,
  setSelectedBatch,
  allBatches,
  isLoading,
}) => {
  const [qnaArr, setQnaArr] = useState([]);
  const [moduleIds, setModuleIds] = useState([]);

  useEffect(() => {
    if (qnaList && qnaList.length > 0) {
      const qna = [];
      const moduleId = [];
      for (let i = 0; i < qnaList.length; i++) {
        if (qnaList[i].qna.length > 0) {
          qna.push(qnaList[i].qna);
          for (let y = 0; y < qnaList[i].qna.length; y++) {
            moduleId.push(qnaList[i]._id);
          }
        }
      }
      setQnaArr([].concat(...qna));
      setModuleIds(moduleId);
    }
  }, [qnaList]);

  return (
    <div className="qna_accordian_container">
      <div className="qna_accordian_options">
        <div className="qna_accordian_links">
          <NavLink to={"/qna"}>All</NavLink>
          <NavLink to={"/qna-answered"}>Answered</NavLink>
          <NavLink to={"/qna-unanswered"}>Unanswered</NavLink>
        </div>
        <div className="qna_accordian_select">
          <select
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
          >
            <option value={""}>Select Batch</option>
            {allBatches?.length > 0 &&
              allBatches.map((data, i) => {
                return (
                  <option value={data.batch_name}>{data.batch_name}</option>
                );
              })}
          </select>
        </div>
      </div>
      {isLoading ? (
        <Loader Height={"65vh"} />
      ) : (
        <div className="qna_accordian_scroll scrollbar-template">
          <Accordion defaultActiveKey="" className=" qna_accordian_div">
            {qnaArr.map((data, index) => {
              return (
                <QnaItems
                  data={data}
                  batchName={batchName}
                  index={index}
                  moduleID={moduleIds[index]}
                />
              );
            })}
          </Accordion>
          {isLoading === false && qnaArr.length === 0 && (
            <h4 className="text-black text-center mt-5 ">No QnA Found!</h4>
          )}
        </div>
      )}
    </div>
  );
};

export default QnaAccordian;

const QnaItems = ({ data, index, batchName, moduleID, num }) => {
  const [isOpen, setIsopen] = useState(false);
  const [isInput, setIsInput] = useState(false);

  const dispatch = useDispatch();

  const handleAccordionToggle = () => {
    setIsopen(!isOpen);
  };

  const profileData = useSelector((state) => state.profileData.userData);
  const [ansInp, setAnsInp] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [ansId, setansId] = useState("");

  const [batchInfo, setBatchInfo] = useState("");

  useEffect(() => {
    if (data && profileData && Object.keys(profileData).length > 0) {
      const filerArr = data.answers.find(
        (data) => data.user._id === profileData._id
      );
      if (filerArr) {
        setAnsInp(filerArr.ans);
        setansId(filerArr._id);
        setIsEdit(true);
      } else {
        setIsEdit(false);
        setAnsInp("");
        setansId("");
      }
    }
  }, [profileData, data]);

  const postQnaAnswer = () => {
    const url = `/batch/qna-ask-answer`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = {
      user: profileData._id,
      answer: ansInp,
      questionId: data._id,
    };

    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        // setIsUpdate((p) => !p);
        const notifyData = {
          recipientArr: [data?.question?.user?._id],
          messages: {
            notification_msg: `Instructor Responded to Your Query.`,
            date: new Date(),
            notification_type: "qna",
          },
        };
        dispatch(asyncSendNotification(notifyData));
        notifySuccess(message);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err.response);
      });
  };

  const updateQnaAnswer = () => {
    const url = `/batch/qna-answer-edit`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = {
      answerId: ansId,
      answer: ansInp,
      questionId: data._id,
    };

    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        // setIsUpdate((p) => !p);
        notifySuccess(message);
        const notifyData = {
          recipientArr: [data?.question?.user?._id],
          messages: {
            notification_msg: `Instructor Responded to Your Query`,
            date: new Date(),
            notification_type: "qna",
          },
        };
        dispatch(asyncSendNotification(notifyData));
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err.response);
      });
  };

  const handleClick = () => {
    if (isEdit) {
      updateQnaAnswer();
    } else {
      postQnaAnswer();
    }
  };

  useEffect(() => {
    if (batchName && batchName.length > 0) {
      const currentBatchName = batchName.find((arr) =>
        arr.modules.includes(moduleID)
      );
      setBatchInfo(currentBatchName);
    }
  }, [batchName]);

  return (
    <Accordion.Item
      eventKey={index}
      className={isOpen ? "qna_items qna_items_active" : "qna_items"}
      key={index}
      onClick={handleAccordionToggle}
    >
      <Accordion.Header>
        <div className="program_preview_accordin_header qna_header">
          <div className="qna_header_heading">
            <h3>
              {index + 1}. {data.question.query}
            </h3>
            {/* <span>{batchInfo.batch_name}</span> */}
          </div>
          <div className="qna_header_date">
            {/* <span>{moment(data.question.date).format("DD MMM YYYY LT")}</span> */}
            {isOpen ? <MinusIconCircle /> : <PlusIconCircle />}
          </div>
        </div>
        <div className="program_preview_info">
          <span className="program_preview_info_name">
            {batchInfo.batch_name}
          </span>
          <span className="program_preview_info_date">
            {isEdit && <b>answered:</b>}
            {moment(data.question.date).format("DD MMM YYYY LT")}
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div
          className="program_preview_accordin_body qna_body"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="qna_body_user">
            <img
              key={data?.question?.user?.image}
              src={data?.question?.user?.image}
              alt=""
            />
            <span>{data?.question?.user?.username}</span>
          </div>
          {isEdit && !isInput && (
            <div className="qna_body_desc">
              <p key={index}>{ansInp}</p>
              <div className="d-flex justify-content-end">
                <FiEdit
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsInput(true);
                  }}
                />
              </div>
            </div>
          )}{" "}
          {(!isEdit || isInput) && (
            <div className="qna_body_inp">
              <textarea
                rows={"3"}
                placeholder="Enter Your answer"
                value={ansInp}
                onChange={(e) => setAnsInp(e.target.value)}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick();
                }}
              >
                {isEdit ? "Update" : "Send"}
              </button>
            </div>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};
