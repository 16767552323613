import React, { useEffect, useState } from "react";
import DashboardScheduleList from "./DashboardScheduleList";
import DashboardScheduleLive from "./DashboardScheduleLive";
import axios from "../../api/axios";
import { useSelector } from "react-redux";
import DashboardGraph from "./DashboardGraph";
import DashboardCalendar from "./DashboardCalendar";

const DashboardDetail = () => {
  const profileData = useSelector((state) => state.profileData.userData);
  const [allSchedules, setAllSchedules] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const getAllBatchSchedules = () => {
    const url = `/batch/all-schedules/${profileData._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllSchedules(data);
        if (data && data.length > 0) {
          setCurrentSchedule({
            lesson: data[0].modules[0].lessons[0],
            batch_name: data[0].batch_name,
            meetLink: data[0].meetlink,
            module_title: data[0].modules[0].title,
          });
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.message || "");
      });
  };

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      getAllBatchSchedules();
    }
  }, [profileData, isUpdate]);

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-8 col-12">
        <DashboardScheduleLive
          currentSchedule={currentSchedule.lesson}
          meetlink={currentSchedule.meetLink}
          setIsUpdate={setIsUpdate}
        />
        <DashboardScheduleList allSchedules={allSchedules || []} />
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 col-12">
        <DashboardGraph />
        <DashboardCalendar value={[new Date()]} />
      </div>
    </div>
  );
};

export default DashboardDetail;
