import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/authSlice";
import profileSlice from "../slice/profileSlice";
import batchSlice from "../slice/batchSlice";
import notificationSlice from "../slice/notificationSlice";

export const store = configureStore({
  reducer: {
    authData: authSlice,
    profileData: profileSlice,
    batchData: batchSlice,
    notificationData: notificationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
