import React, { useState } from "react";
import { IoMdPlayCircle } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FaClipboardCheck } from "react-icons/fa";
import { notifySuccess } from "../../actions/customFn";
import { MdOutlinePlayCircleFilled } from "react-icons/md";

const VideoComponent = ({
  imgSrc,
  videoUrl,
  iconSize,
  Height,
  Width,
  iconpos,
}) => {
  let defaultPos = {
    top: "36%",
    right: "42%",
  };
  let defaultClosePos = {
    top: "3%",
    right: "0%",
  };
  if (iconpos) {
    defaultPos = iconpos;
  }
  const [isActive, setIsActive] = useState(false);

  const copyUrl = () => {
    notifySuccess("url copied!");
    navigator.clipboard.writeText(videoUrl);
  };
  return (
    <div className="video_wrapper_container">
      {!isActive && (
        <>
          <img alt="" src={imgSrc} style={{ height: Height, width: Width }} />
          <MdOutlinePlayCircleFilled
            onClick={() => setIsActive(true)}
            style={{
              fontSize: iconSize,
              borderRadius: "50%",
              backgroundColor: "#fff",
              ...defaultPos,
            }}
          />
          <FaClipboardCheck
            style={{
              fontSize: "26px",
              fill: "#11a63b",
              right: "2%",
              top: "3%",
            }}
            onClick={() => copyUrl()}
          />
        </>
      )}
      {isActive && (
        <>
          <video
            style={{ height: Height, width: Width }}
            controls
            autoPlay={true}
            key={videoUrl}
          >
            <source src={videoUrl}></source>
          </video>
          <IoCloseSharp
            style={{ fontSize: "30px", ...defaultClosePos }}
            onClick={() => setIsActive(false)}
          />
        </>
      )}
    </div>
  );
};

export default VideoComponent;
