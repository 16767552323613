import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaFileAlt } from "react-icons/fa";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import SmallLoader from "../../utils/SmallLoader";
import { useDispatch } from "react-redux";
import { asyncSendNotification } from "../../actions/notificationApi";

const ACCEPT_FILE_TYPES =
  ".pdf,.doc,.xls,.ppt,.txt,.csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation.ipynb";

const ResourceModal = ({
  modalShow,
  setShowModal,
  setIsUpdate,
  setIsLoading,
  moduleTitle,
}) => {
  const [file, setFile] = useState("");
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [refrence, setRefrence] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();

  const errors = {};
  const [formErrors, setFormErrors] = useState({});
  const [btnTxt, setBtnTxt] = useState("Upload");

  const [studentList, setStudentList] = useState([]);

  const onFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setRefrence("");
    }
  };

  const initialState = () => {
    setName("");
    setRefrence("");
    setFile("");
  };
  const validation = () => {
    if (name.length === 0) {
      errors.name = "Please provide name!";
    }
    if (refrence === "" && file === "") {
      errors.fileErr = "please provide either resource file or refrence url";
    }
    setFormErrors(errors);
  };

  const handleUploadStudyMaterial = () => {
    validation();
    if (Object.keys(errors).length === 0) {
      if (refrence !== "") {
        addRefrence();
      } else if (file !== "") {
        addResource();
      }
    }
  };

  const addRefrence = () => {
    setBtnTxt(<SmallLoader color={"#fff"} />);
    const url = `/batch/study-material/refrence`;
    const data = {
      refrence_link: refrence,
      name,
      id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setBtnTxt("Upload");
        notifySuccess(message);
        setIsLoading(true);
        setIsUpdate((p) => !p);
        setShowModal(false);
        initialState();
        const studentIds = studentList.enroll_students.map((doc) => {
          return doc._id;
        });
        const data = {
          recipientArr: studentIds,
          messages: {
            notification_msg: `New Reference Uploaded in <b>${moduleTitle}</b> Module`,
            date: new Date(),
            notification_type: "program",
          },
        };
        dispatch(asyncSendNotification(data));
      })
      .catch((err) => {
        console.log(err);
        setBtnTxt("Upload");
        setShowModal(false);
        notifyDanger(err?.response?.message || "Some error Occured");
      });
  };

  const addResource = () => {
    setBtnTxt(<SmallLoader color={"#fff"} />);
    const url = `/batch/study-material/resource`;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("id", id);
    formData.append("file", file);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(true);
        setBtnTxt("Upload");
        setIsUpdate((p) => !p);
        setShowModal(false);
        initialState();
        const studentIds = studentList.enroll_students.map((doc) => {
          return doc._id;
        });
        const data = {
          recipientArr: studentIds,
          messages: {
            notification_msg:`New Resource Uploaded in <b>${moduleTitle}</b> Module`,
            date: new Date(),
            notification_type: "program",
          },
        };
        dispatch(asyncSendNotification(data));
      })
      .catch((err) => {
        console.log(err);
        setBtnTxt("Upload");
        setShowModal(false);
        notifyDanger(err?.response?.message || "Some error Occured");
      });
  };

  useEffect(() => {
    const url = `/batch/getstudent-by-moduleId/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setStudentList(data);
      })
      .catch((err) => {
        console.log(err.response || err);
      });
  }, [id]);

  return (
    <Modal
      show={modalShow}
      className="bootstrap-modal-custom upload-video-modal"
      onHide={() => {
        setShowModal(false);
        initialState();
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="upload-video-modal-body">
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => {
              setShowModal(false);
              initialState();
            }}
          />

          <h2>upload new Study Material</h2>

          <div className="upload-video-modal-form">
            <div className="upload-video-modal-inp">
              <label>Resource Title</label>
              <input
                placeholder="Enter resource title"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {formErrors.name && (
                <div className="form_error_div">{formErrors.name}</div>
              )}
            </div>
            <div className="upload-video-modal-inp">
              <label>Upload File</label>
              {file ? (
                <div className="dropzone">
                  <div className="upload-video-modal-file-select">
                    <div className="upload-video-modal-file-txt">
                      <FaFileAlt /> <span>{file.name}</span>
                    </div>
                    <div className="upload-video-modal-file-close">
                      <AiOutlineClose onClick={() => setFile("")} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <label
                    htmlFor="fileID"
                    className={`dropzone  ${file.length > 0 ? "active" : ""}`}
                  >
                    <button
                      onClick={(e) => {
                        fileInputRef.current.click();
                      }}
                    >
                      Browse Your Device
                    </button>
                    <p>or</p>
                    <p>Drag & Drop here</p>
                  </label>
                  <input
                    className="d-none"
                    type="file"
                    id="fileID"
                    onChange={onFileChange}
                    ref={fileInputRef}
                    // accept={ACCEPT_FILE_TYPES}
                  />
                </>
              )}

              <div className="upload-video-modal-or">
                <span></span>
                <b>Or</b>
                <span></span>
              </div>
              <input
                placeholder="Paste the Video URL "
                type="text"
                value={refrence}
                onChange={(e) => {
                  setRefrence(e.target.value);
                  setFile("");
                }}
              />

              {formErrors.fileErr && (
                <div className="form_error_div">{formErrors.fileErr}</div>
              )}
            </div>

            <div className="upload-video-modal-submit">
              <button
                disabled={btnTxt !== "Upload"}
                onClick={handleUploadStudyMaterial}
              >
                {btnTxt}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResourceModal;
