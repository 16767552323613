import { createSlice } from "@reduxjs/toolkit";

const batchSlice = createSlice({
  name: "batchData",
  initialState: {
    module_details: {},
    video_data: {},
    batchStudents: [],
    filterBatches: [],
  },
  reducers: {
    setModuleDetails: (state, action) => {
      state.module_details = action.payload;
    },
    setVideoData: (state, action) => {
      state.video_data = action.payload;
    },
    setBatchStudents: (state, action) => {
      state.batchStudents = action.payload;
    },
    setfilterBatches: (state, action) => {
      state.filterBatches = action.payload;
    },
  },
});

export const {
  setModuleDetails,
  setVideoData,
  setBatchStudents,
  setfilterBatches,
} = batchSlice.actions;

export default batchSlice.reducer;
