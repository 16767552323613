import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { BatchLineIcon, BatchLogo } from "../../asset/icons/Icon";
import { FaAngleRight } from "react-icons/fa6";
import leftsvg from "../../asset/icons/left.svg";
import rightsvg from "../../asset/icons/right.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const DUMMY_DATA = [
  {
    batch_name: "DS15NOV4PM2023",
    start_date: "20/03/2024",
    day_str: "MWF",
  },
  {
    batch_name: "DS15NOV4PM2023",
    start_date: "25/03/2024",
    day_str: "TTF",
  },
  {
    batch_name: "DS15NOV4PM2023",
    start_date: "28/03/2024",
    day_str: "MWF",
  },
  {
    batch_name: "DS15NOV4PM2023",
    start_date: "20/03/2024",
    day_str: "MWF",
  },
];

const ACTIVE_BATCH_SLIDER = {
  loop: false,
  margin: 30,
  padding: 0,
  //   center: true,
  nav: true,
  navText: [
    `  <button className="policy_btn_arrow">
    <img alt="" src=${leftsvg} />
    </button>`,
    `  <button className="policy_btn_arrow">
    <img alt="" src=${rightsvg} />
    </button>`,
  ],
  dots: false,
  autoplay: false,
  autoplaySpeed: 300,

  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    750: {
      items: 3,
    },
    1000: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

const ActiveBatchCards = () => {
  const [activeBatch, setActiveBatch] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);

  const navigate = useNavigate();

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      const filterbatch = profileData.batches.filter((data) => {
        return data.active === true;
      });
      setActiveBatch(filterbatch);
    }
  }, [profileData]);

  const getScheduleStr = (schedule) => {
    let str = "";
    if (schedule?.length > 0) {
      for (let i = 0; i < schedule.length; i++) {
        str += schedule[i].day + " ";
      }
      return str;
    }
  };

  return (
    <div className="activebatch_card_container">
      {activeBatch?.length > 0 && (
        <ReactOwlCarousel
          {...ACTIVE_BATCH_SLIDER}
          className="owl-carousel owl-theme activebatch_card_carousel"
        >
          {activeBatch.map((data, i) => {
            return (
              <div className="activebatch_card_item" key={i}>
                <div className="activebatch_card_item_title">
                  <BatchLogo />
                  <span>{data?.custom_name  || data.batch_name}</span>
                </div>
                <div className="activebatch_card_item_info">
                  <div className="activebatch_card_item_svg">
                    <BatchLineIcon />
                  </div>
                  <div className="activebatch_card_item_desc">
                    <h4>
                      Batch Start -{" "}
                      {moment(data.batch_start).format("DD MMM YYYY")}
                    </h4>
                    <h4>
                      Schedule:{" "}
                      {data.schedule_str ||
                        getScheduleStr(data?.schedule) ||
                        "No Schedule Found!"}
                    </h4>
                  </div>
                </div>
                <div className="activebatch_card_item_btn">
                  <button onClick={() => navigate(`/batch/module/${data._id}`)}>
                    <span>View Batch</span>
                    <FaAngleRight />
                  </button>
                </div>
              </div>
            );
          })}
        </ReactOwlCarousel>
      )}
    </div>
  );
};

export default ActiveBatchCards;
