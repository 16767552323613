import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ModuleViewHeader from "../components/moduleview/ModuleViewHeader";
import ModuleVideoHeader from "../components/moduleVideos/ModuleVideoHeader";
import ModuleVideoCards from "../components/moduleVideos/ModuleVideoCards";
import UploadVideoModal from "../components/moduleVideos/videoupload/UploadVideoModal";
import { asyncGetModuleDetails } from "../actions/batchApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../utils/Loader";

const ModuleVideo = () => {
  const [modalShow, setShowModal] = useState(false);
  const [moduleTitle, setModuleTitle] = useState("");
  const [allVideos, setAllVideos] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const module_details = useSelector((state) => state.batchData.module_details);

  useEffect(() => {
    setIsLoading(true);
    dispatch(asyncGetModuleDetails(id, setIsLoading));
  }, [id, isUpdate]);

  useEffect(() => {
    if (module_details && Object.keys(module_details).length > 0) {
      setModuleTitle(module_details.title);
      setAllVideos(module_details.lessons);
    }
  }, [module_details]);

  return (
    <LayoutWrapper>
      <Header />
      <ModuleViewHeader title={"Lectures"} />
      <ModuleVideoHeader
        Component={
          modalShow ? (
            <UploadVideoModal
              isEdit={false}
              modalShow={modalShow}
              setShowModal={setShowModal}
              setIsUpdate={setIsUpdate}
              moduleTitle={moduleTitle}
            />
          ) : (
            ""
          )
        }
        setShowModal={setShowModal}
        btnTxt={"Add Video"}
        moduleTitle={moduleTitle}
        setIsUpdate={setIsUpdate}
      />
      {isLoading ? (
        <Loader Height={"40vh"} />
      ) : (
        <div className="module_videolist_div scrollbar-template">
          {allVideos.map((data, index) => {
            return (
              <ModuleVideoCards
                data={data}
                index={index}
                id={id}
                setIsUpdate={setIsUpdate}
                setIsLoading={setIsLoading}
                moduleTitle={moduleTitle}
              />
            );
          })}
        </div>
      )}
    </LayoutWrapper>
  );
};

export default ModuleVideo;
