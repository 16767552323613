import axios from "axios";

const instance = axios.create({
  baseURL: "https://us-central1-oeson-v2-backend.cloudfunctions.net/api/api",
});

export default instance;

// https://us-central1-oeson-v2-backend.cloudfunctions.net/api/api
// http://localhost:8000/api

