import React, { useEffect, useState } from "react";
import { SlPencil } from "react-icons/sl";
import AnnouncementModal from "./AnnouncementModal";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import { notifyDanger } from "../../actions/customFn";
import moment from "moment";

const DUMMY_DATA = [
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
  {
    title:
      "Good Morning All, your assignment will be due on 20 jan. Submit your assignment before due date to avoid inconvinient.",
    name: "Minakshi Gautam",
    date: "12 Jan 3:03PM",
  },
];

const AnnouncementList = () => {
  const [modalShow, setShowModal] = useState(false);

  const [allAnnouncement, setAllAnnouncement] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);
  const [isUpdate , setIsUpdate] = useState(false)

  useEffect(() => {
    const url = "/notification/my-announcement";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        announcer: profileData._id,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllAnnouncement(data);
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  }, [profileData , isUpdate]);


  return (
    <div className="announcement_list_container">
      <div className="announcement_list_div scrollbar-template">
        {allAnnouncement?.length > 0 ? (
          allAnnouncement.map((data, i) => {
            return (
              <div className="announcement_list_items" key={i}>
                <p>{data.description}</p>
                <div className="announcement_list_item_info">
                  <h4>At {moment(data.createdAt).format("DD MMM YYYY LT")}</h4>
                  <h3>By {data.announcer.username}</h3>
                </div>
              </div>
            );
          })
        ) : (
          <h4>No Announcement Created!</h4>
        )}
      </div>

      <div className="announcement_list_btn">
        <button onClick={() => setShowModal(true)}>
          <SlPencil />
        </button>
      </div>
      <AnnouncementModal setIsUpdate={setIsUpdate} modalShow={modalShow} setShowModal={setShowModal} />
    </div>
  );
};

export default AnnouncementList;
