import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { asyncUpdateBatchSession } from "../../actions/batchApi";

const DashboardScheduleLive = ({ currentSchedule, meetlink , setIsUpdate }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <AfterCoundownEnd
          setIsCompleted={setIsCompleted}
          currentSchedule={currentSchedule}
          setIsUpdate={setIsUpdate}
        />
      );
    } else {
      return (
        <div className="countdown_div countdown_live_schedule">
          <div className=" count_down_txt">
            <h6>{days || "0"}</h6>
            <h6>Days</h6>
          </div>
          <div className="count_down_txt">
            <h6>{hours || "0"}</h6>
            <h6>Hours</h6>
          </div>
          <div className="count_down_txt">
            <h6>{minutes || "0"}</h6>
            <h6>Minutes</h6>
          </div>
          <div className="count_down_txt">
            <h6>{seconds || "0"}</h6>
            <h6>Seconds</h6>
          </div>
        </div>
      );
    }
  };

  const onComplete = () => {
    setIsCompleted(true);
  };

  const handleMeetLink = () => {
    if (isCompleted) {
      let url = meetlink;
      if (!meetlink.includes("https://")) {
        url = "https://" + meetlink;
      }
      window.open(url, "_blank");
    }
  };

  return (
    <div className="dashboard_schedule_div">
      {currentSchedule && Object.keys(currentSchedule).length > 0 ? (
        <div
          className="dashboard_schedule_container"
          onClick={() => handleMeetLink()}
        >
          <div className="dashboard_schedule_img">
            {isCompleted && <span className="live_span">Live</span>}
            <Countdown
              date={new Date(currentSchedule.schedule_time)}
              onComplete={onComplete}
              renderer={renderer}
              autoStart={true}
            />
            {!isCompleted && (
              <span className="dashboard_schedule_upcoming">
                Upcoming Session In
              </span>
            )}
          </div>
          <div className="dashboard_schedule_info">
            <div className="dashboard_schedule_desc">
              <h4>{currentSchedule.title}</h4>
              <p className="scrollbar-template">{currentSchedule.content}</p>
            </div>
            {isCompleted && (
              <div className="dashboard_schedule_btn">
                <button>View</button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="dashboard_schedule_container p-3"
          style={{ height: "300px " }}
        >
          <h5 className="text-center text-black w-100">No Schedule Found!</h5>
        </div>
      )}
    </div>
  );
};

export default DashboardScheduleLive;

const AfterCoundownEnd = ({ setIsCompleted, currentSchedule, setIsUpdate }) => {
  const [sessionStart, setSessionStart] = useState("");
  const [sessionEnd, setSessionEnd] = useState("");
  const [txt, setTxt] = useState("");
  const currentTime = moment();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsCompleted(true);
  }, []);

  // console.log(currentSchedule._id, "ID ");

  useEffect(() => {
    setSessionStart(moment(currentSchedule.schedule_time));
    const timeAfterOneHour = moment(currentSchedule.schedule_time).add(
      1,
      "hour"
    );

    setSessionEnd(timeAfterOneHour);
  }, [currentSchedule]);

  useEffect(() => {
    timeOutFunc();
    // 600000 10 min ,  4000 4 sec
  }, [sessionEnd]);

  const timeOutFunc = () => {
    if (currentTime && sessionEnd) {
      if (currentTime.isBefore(sessionEnd)) {
        // console.log("Current time is before the given time.");
      } else if (currentTime.isSame(sessionEnd)) {
        // console.log("Current time is the same as the given time.");
      } else {
        // console.log("Current time is after the given time.");
        if (currentSchedule.isLive === false) {
          return;
        } else {
          const data = {
            lessonId: currentSchedule._id,
            isLive: false,
          };
          dispatch(asyncUpdateBatchSession(data, setIsUpdate));
        }
      }
    }
  };

  return <div className=""></div>;
};
