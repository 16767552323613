import React from 'react'
import { images } from '../../actions/customFn'

const LoginImg = () => {
  return (
    <div className='loginimg_container'>
        <img alt='' src={images["loginimg.png"]} />
    </div>
  )
}

export default LoginImg