import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DashboardGraph = () => {
  const [allStudents, setAllStudents] = useState([]);
  const [batchName, setBatchName] = useState([]);
  const profileData = useSelector((state) => state.profileData.userData);
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      const filterbatch = profileData.batches.filter((data) => {
        return data.active === true;
      });
      const allStudents = filterbatch.map((data) => {
        return data.enroll_students.length;
      });
      const allBatchName = filterbatch.map((data) => {
        return data.batch_name.length < 15
          ? " " + data.batch_name
          : data.batch_name;
      });
      setAllStudents(allStudents);
      setBatchName(allBatchName);
    }
  }, [profileData]);

  useEffect(() => {
    if (allStudents?.length > 0 && batchName?.length > 0) {
      const data = {
        labels: batchName,
        datasets: [
          {
            label: "No. of Students:",
            data: allStudents,
            backgroundColor: [
              "rgba(109, 185, 255)",
              "rgba(153, 102, 255)",
              "rgba(255, 159, 64)",
              "rgba(255, 0, 0)",
              "rgba(60, 179, 113)",
              "rgba(0, 0, 255)",
              "rgba(109, 185, 255)",
              "rgba(153, 102, 255)",
              "rgba(255, 159, 64)",
              "rgba(255, 0, 0)",
              "rgba(60, 179, 113)",
              "rgba(0, 0, 255)",
            ],
          },
        ],
      };

      setGraphData(data);
    }
  }, [allStudents, batchName]);

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
    },
  };

  return (
    <div className="dashboard_graph_container">
      <div className="dashboard_graph_heading">
        <h2>Batch Division</h2>
      </div>
      <div className="dashboard_graph_div">
        {graphData && Object.keys(graphData).length > 0 ? (
          <Pie data={graphData} options={options} />
        ) : (
          <h5 className="text-black text-center mt-3 mb-3">
            No Active Batches
          </h5>
        )}
      </div>
    </div>
  );
};

export default DashboardGraph;

// "DS02JAN4PM2024"
// "DS02JAN09AM2024"
// "DS15FEB09AM2024"
// "DS15FEB4PM2024"
// "DS30MAR03PM2024"
// "DS30MAR08AM2024"
