import React, { useRef, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setfilterBatches } from "../../slice/batchSlice";
import { NavLink } from "react-router-dom";

const BatchHeader = ({ allBatches }) => {
  const [isFocus, setIsFocus] = useState(false);
  const dispatch = useDispatch();
  // const searchRef = useRef("");
  const filterData = (query) => {
    const filterArr = allBatches.filter((item) => {
      if (item.custom_name) {
        return (
          item.batch_name.toLowerCase().includes(query.toLowerCase()) ||
          item.custom_name.toLowerCase().includes(query.toLowerCase())
        );
      } else {
        return item.batch_name.toLowerCase().includes(query.toLowerCase());
      }
    });
    dispatch(setfilterBatches(filterArr));
  };

  return (
    <div className="batch_header_container">
      <div className="batch_header_content">
        <NavLink to={-1}>
          <h2>
            <FaAngleLeft />
            <span>Batches</span>
          </h2>
        </NavLink>
      </div>
      <div className="batch_header_inp">
        <FiSearch />
        <input
          onChange={(e) => filterData(e.target.value)}
          placeholder="search anything here"
        />
      </div>
    </div>
  );
};

export default BatchHeader;
