import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import StudentReportHeader from "../components/studentReport/StudentReportHeader";
import StudentReportForm from "../components/studentReport/StudentReportForm";

const StudentReport = () => {
  return (
    <LayoutWrapper>
      <Header /> 
      <StudentReportHeader />
      <StudentReportForm />
    </LayoutWrapper>
  );
};

export default StudentReport;
