import React from "react";
import { Circles } from "react-loader-spinner";

const Loader = ({ Height }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center w-100 "
      style={{ height: Height }}
    >
      <Circles
        height="80"
        width="80"
        color="#024c87"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
