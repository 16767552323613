import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import WorkForm from "../components/assignment/WorkForm";

const AssignmentForm = () => {
  return (
    <LayoutWrapper>
      <Header />
      <WorkForm />
    </LayoutWrapper>
  );
};

export default AssignmentForm;
