import React from "react";
import Layoutwrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ActiveBatchCards from "../components/home/ActiveBatchCards";
import HomeHeader from "../components/home/HomeHeader";
import DashboardDetail from "../components/home/DashboardDetail";

const Home = () => {
  return (
    <Layoutwrapper>
      <Header />
      <HomeHeader />
      <div className="scrollbar-template homepage_div_page">
        <ActiveBatchCards />
        <DashboardDetail />
      </div>
    </Layoutwrapper>
  );
};

export default Home;
