import React, { useRef, useState } from "react";
import VideoComponent from "../layout/VideoComponent";
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
  useOutsideClick,
} from "../../actions/customFn";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdEditDocument } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import UploadVideoModal from "./videoupload/UploadVideoModal";

const ModuleVideoCards = ({ data, index, id, setIsUpdate, setIsLoading  , moduleTitle}) => {
  const shortdesc = data.content.split("").slice(0, 250);
  shortdesc.push("...");
  const [isOpen, setIsOpen] = useState(false);
  const videoOptionRef = useRef(null);
  useOutsideClick(videoOptionRef, setIsOpen);
  const navigate = useNavigate();

  const [modalShow, setShowModal] = useState(false);

  const handleDeleteVideo = () => {
    setIsLoading(true);
    const url = `/batch/upload-video/${data._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err.response);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  return (
    <div className="module_video_cards_container" key={index}>
      <div className="module_video_cards_video">
        {data.thumbnail ? (
          <VideoComponent
            imgSrc={data.thumbnail}
            videoUrl={data.videoUrl}
            Height={"161px"}
            Width={"254px"}
            iconSize={"42px"}
          />
        ) : (
          <img
            alt=""
            src={images["video-placeholder.png"]}
            height={"161px"}
            width={"254px"}
          />
        )}
      </div>
      <div className="module_video_cards_description">
        <div className="module_video_cards_title">
          <h2>{data.title}</h2>
          <div className="module_video_cards_options">
            <HiDotsHorizontal onClick={() => setIsOpen(true)} />
            {isOpen && (
              <div
                className="module-schedule-options-div module_video_cards_options-div"
                ref={videoOptionRef}
              >
                <div
                  className="module-schedule-options-item"
                  onClick={() => setShowModal(true)}
                >
                  <MdEditDocument />
                  <span>Edit</span>
                </div>
                <div
                  className="module-schedule-options-item"
                  onClick={() => {
                    confirmToast(
                      "Are you want to delete this video?",
                      handleDeleteVideo
                    );
                  }}
                >
                  <RiDeleteBinFill />
                  <span>delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <h3>{shortdesc}</h3>
        {data.thumbnail ? (
          <button
            onClick={() =>
              navigate(`/batch/module/video/view/${id}/${data._id}`)
            }
          >
            View
          </button>
        ) : (
          ""
        )}
      </div>
      {modalShow && (
        <UploadVideoModal
          modalShow={modalShow}
          setShowModal={setShowModal}
          setIsUpdate={setIsUpdate}
          isEdit={true}
          videoData={data}
          moduleTitle={moduleTitle}
        />
      )}
    </div>
  );
};

export default ModuleVideoCards;
