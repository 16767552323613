import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import Loader from "../../utils/Loader";
import StudentReportModal from "./StudentReportModal";
import { images } from "../../actions/customFn";
import { IoChevronDown } from "react-icons/io5";

const StudentReportForm = () => {
  const DUMMY_DATA = [
    {
      phone: "+91 123456789",
      student_name: "Raghav Sharma",
      rating: 5,
      email: "raghav@example.com",
      assigned_date: "30/03/2024",
    },
    {
      phone: "+91 123456789",
      student_name: "Test User",
      rating: 3,
      email: "test@example.com",
      assigned_date: "01/04/2024",
    },
    {
      phone: "+91 123456789",
      student_name: "Test User 2",
      rating: 9,
      email: "test2@example.com",
      assigned_date: "10/04/2024",
    },
    {
      phone: "+91 123456789",
      student_name: "TEST USER 4 ",
      rating: 10,
      email: "test4@example.com",
      assigned_date: "30/03/2024",
    },
  ];

  const [allBatches, setAllBatches] = useState([]);
  const [searchInp, setSearchInp] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const profileData = useSelector((state) => state.profileData.userData);
  const [isLoading, setIsLoading] = useState(false);

  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) setAllBatches(profileData.batches);
  }, [profileData]);

  const getAllBatchStudents = () => {
    setIsLoading(true);
    const url = `batch/student-report`;
    const paramObj = {
      batchId: selectedBatch,
    };
    if (searchInp !== "") {
      paramObj.searchTxt = searchInp;
    }
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsLoading(false);

        setStudentList(data.enroll_students);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  return (
    <div className="student_reportform_container">
      <div className="student_reportform_div">
        <div className="student_reportform_div_select">
          <select
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
          >
            <option value={""}>Select Batch</option>
            {allBatches.map((item, i) => {
              return (
                <option key={i} value={item._id}>
                  {item.batch_name}
                </option>
              );
            })}
          </select>
          <IoChevronDown className="student_reportform_div_select_dropdown" />
        </div>
        <div className="student_reportform_input">
          <input
            placeholder="Enter student email"
            value={searchInp}
            onChange={(e) => setSearchInp(e.target.value)}
          />
          <button onClick={() => getAllBatchStudents()}>Search</button>
        </div>
      </div>
      <div className="allassignment_table_container">
        <div className="assignment_view_table_div  report_view_table_div scrollbar-template">
          {isLoading ? (
            <div className="mt-5">
              <Loader />
            </div>
          ) : studentList?.length > 0 ? (
            <table className="table-template-style allassignment_table_tag">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {studentList?.length > 0 &&
                  studentList.map((data, i) => {
                    return (
                      <StudentReportItem
                        data={data}
                        i={i}
                        selectedBatch={selectedBatch}
                      />
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <img src={images["student-report-img.png"]} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentReportForm;

const StudentReportItem = ({ data, i, selectedBatch }) => {
  const [modalShow, setShowModal] = useState(false);

  return (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>{data.username || "Not Found"}</td>
      <td>{data.email || "Not Found"}</td>
      <td>{data.phone_no || "Not Found"}</td>
      <td>
        <button onClick={() => setShowModal(true)}>View</button>
      </td>
      {modalShow && (
        <StudentReportModal
          modalShow={modalShow}
          setShowModal={setShowModal}
          ID={data._id}
          selectedBatch={selectedBatch}
        />
      )}
    </tr>
  );
};
