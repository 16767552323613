import React, { useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { MdEditDocument } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import { SortArrow } from "../../asset/icons/Icon";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import moment from "moment";
import Loader from "../../utils/Loader";
import {
  confirmToast,
  notifyDanger,
  notifySuccess,
  useOutsideClick,
} from "../../actions/customFn";
import { useNavigate } from "react-router-dom";

const DUMMY_DATA = [
  {
    name: "assignment 1 ",
    batch_name: "DA02JAN04PM2024",
    submissions: 5,
    due_date: "20/03/2024",
    assigned_date: "30/03/2024",
  },
  {
    name: "assignment 2 ",
    batch_name: "DA02JAN04PM2024",
    submissions: 3,
    due_date: "23/03/2024",
    assigned_date: "01/04/2024",
  },
  {
    name: "assignment 3 ",
    batch_name: "DA02JAN04PM2024",
    submissions: 9,
    due_date: "04/04/2024",
    assigned_date: "10/04/2024",
  },
  {
    name: "assignment 4",
    batch_name: "DA02JAN04PM2024",
    submissions: 10,
    due_date: "20/03/2024",
    assigned_date: "30/03/2024",
  },
];
const AllAssignmentTable = () => {
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [filterOption, setFilterOption] = useState({
    batch: "",
    work_type: "",
    priority: "",
  });
  const [assignmentArr, setAssignmentArr] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const profileData = useSelector((state) => state.profileData.userData);
  const optionRef = useRef(null);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOption({ ...filterOption, [name]: value });
  };

  const getAssignment = (ID) => {
    setIsLoading(true);
    const url = `/batch/assignment/assignee/${ID}`;
    const paramObj = {};
    if (filterOption.batch !== "") {
      paramObj.batchId = filterOption.batch;
    }
    if (filterOption.work_type !== "") {
      paramObj.work_type = filterOption.work_type;
    }
    if (filterOption.priority !== "") {
      paramObj.priority = filterOption.priority;
    }
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAssignmentArr(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
  };

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      getAssignment(profileData._id);
      setAllBatches(profileData.batches);
    }
  }, [
    profileData,
    filterOption.batch,
    filterOption.work_type,
    filterOption.priority,
    isUpdate,
  ]);

  useOutsideClick(optionRef, setIsSortOpen);

  return (
    <div className="allassignment_table_container">
      <div className="allassignment_table_filter">
        <div className="allassignment_table_sort">
          <button onClick={() => setIsSortOpen((p) => !p)}>
            <SortArrow />
            <span>Sort</span>
          </button>
          {isSortOpen && (
            <div className="allassignment_table_sort_list" ref={optionRef}>
              <div className="allassignment_table_sort_list_item">
                <div className="allassignment_table_sort_heading">
                  <h4>Priority</h4>
                  <span
                    onClick={() =>
                      setFilterOption({
                        ...filterOption,
                        priority: "",
                      })
                    }
                  >
                    Clear
                  </span>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <input
                    type="radio"
                    checked={filterOption.priority === "Intermediate"}
                    onChange={() =>
                      setFilterOption({
                        ...filterOption,
                        priority: "Intermediate",
                      })
                    }
                  />
                  <label>Intermediate</label>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <input
                    type="radio"
                    id="hignid"
                    checked={filterOption.priority === "High"}
                    onChange={() => {
                      setFilterOption({
                        ...filterOption,
                        priority: "High",
                      });
                    }}
                  />
                  <label htmlFor="hignid">High</label>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <input
                    type="radio"
                    checked={filterOption.priority === "Medium"}
                    onChange={() =>
                      setFilterOption({
                        ...filterOption,
                        priority: "Medium",
                      })
                    }
                  />
                  <label>Medium</label>
                </div>
              </div>
              <div className="allassignment_table_sort_list_item">
                <div className="allassignment_table_sort_heading">
                  <h4>Type</h4>
                  <span
                    onClick={() =>
                      setFilterOption({
                        ...filterOption,
                        work_type: "",
                      })
                    }
                  >
                    Clear
                  </span>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <input
                    type="radio"
                    checked={filterOption.work_type === "assignment"}
                    onChange={() =>
                      setFilterOption({
                        ...filterOption,
                        work_type: "assignment",
                      })
                    }
                  />
                  <label>Assignment</label>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <input
                    type="radio"
                    checked={filterOption.work_type === "project"}
                    onChange={() =>
                      setFilterOption({
                        ...filterOption,
                        work_type: "project",
                      })
                    }
                  />
                  <label>Project</label>
                </div>
              </div>
              <div className="allassignment_table_sort_list_item">
                <div className="allassignment_table_sort_heading">
                  <h4>Batch</h4>
                </div>
                <div className="allassignment_table_list_item_inp">
                  <select
                    value={filterOption.batch}
                    name="batch"
                    onChange={handleFilterChange}
                  >
                    <option value={""}>Select Batch </option>
                    {allBatches.length > 0 &&
                      allBatches.map((data, i) => {
                        return (
                          <option key={i} value={data._id}>
                            {data.batch_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <Loader Height={"40vh"} />
      ) : (
        <div className="assignment_table_div scrollbar-template">
          <table className="table-template-style allassignment_table_tag">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Work</th>
                <th>Batch Name</th>
                <th>Submissions</th>
                <th>Due Date</th>
                <th>Assigned On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {assignmentArr.map((data, i) => {
                return (
                  <AssignmentTableItem
                    data={data}
                    i={i}
                    setIsUpdate={setIsUpdate}
                    setIsLoading={setIsLoading}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AllAssignmentTable;

const AssignmentTableItem = ({ data, i, setIsUpdate, setIsLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const deadline = moment(data.deadline).format("DD MMM YYYY");
  const startdate = moment(data.createdAt).format("DD MMM YYYY");
  const shorttxt = data.title.split("").slice(0, 25);
  shorttxt.push("...");
  const optionRef = useRef(null);
  useOutsideClick(optionRef, setIsOpen);

  const deleteAssignment = (ID) => {
    const url = `/batch/assignment-delete/${ID}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        notifyDanger(err?.response?.data?.message || "Some Error Occured!");
        console.log(err.response);
      });
  };

  return (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>{shorttxt.join("")}</td>
      <td>{data.batchId.batch_name}</td>
      <td>{data.projectSubmission.length}</td>
      <td>{deadline}</td>
      <td>{startdate}</td>
      <td>
        <div className="assignment_table_btns">
          <button onClick={() => navigate(`/work/view/${data._id}`)}>
            View
          </button>
          <div className="assignment_table_edit">
            <HiDotsVertical onClick={() => setIsOpen((p) => !p)} />
            {isOpen && (
              <div
                className="module-schedule-options-div assignment_table_options"
                ref={optionRef}
              >
                <div
                  className="module-schedule-options-item"
                  onClick={() => navigate(`/work/form/${data._id}`)}
                >
                  <MdEditDocument />
                  <span>Edit</span>
                </div>
                <div
                  className="module-schedule-options-item"
                  onClick={() => {
                    confirmToast("Are you want to delete this work?", () => {
                      setIsLoading(true);
                      deleteAssignment(data._id);
                    });
                  }}
                >
                  <RiDeleteBinFill />
                  <span>delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
