import React from "react";
import { images } from "../../actions/customFn";
import { useNavigate } from "react-router-dom";

const ModuleViewCards = ({ lessonNum, resourceNum, id }) => {
  const navigate = useNavigate();
  return (
    <div className="module_viewcards_container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
          <div className="module_viewcards_item">
            <img alt="" src={images["module-video-img.png"]} />
            <div className="module_viewcards_item_details">
              <div className="module_viewcards_item_title">
                <h3>Module Videos</h3>
                <h4>Lecture Videos</h4>
                <h5>{lessonNum} videos</h5>
              </div>
              <button onClick={() => navigate(`/batch/module/video/${id}`)}>
                View
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="module_viewcards_item">
            <img alt="" src={images["module-pdf-img.png"]} />
            <div className="module_viewcards_item_details">
              <div className="module_viewcards_item_title">
                <h3>Module Resources</h3>
                <h4>Lecture Resources</h4>
                <h5>{resourceNum} resources</h5>
              </div>
              <button
                onClick={() => navigate(`/batch/module/study-material/${id}`)}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleViewCards;
