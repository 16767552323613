import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { asyncCreateModule } from "../../actions/batchApi";
import { ModuleContext } from "../../pages/Module";
import { useParams } from "react-router-dom";
import SmallLoader from "../../utils/SmallLoader";

const CreateModuleModal = ({ modalShow, setShowModal }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [isModuleLoading, setModuleIsLoading] = useState(false);
  const { setIsUpdate, setIsLoading } = useContext(ModuleContext);

  const initialState = () => {
    setShowModal(false);
    setTitle("");
    setIsLoading(true);
  };

  const createModule = (e) => {
    e.preventDefault();
    setModuleIsLoading(true);
    const data = {
      id,
      title,
    };
    dispatch(
      asyncCreateModule(data, setIsUpdate, setModuleIsLoading, initialState)
    );
  };
  return (
    <Modal
      className="create-module-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Body>
        <div className="create-module-modal-body">
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => setShowModal(false)}
          />
          <h2>Create Module</h2>
          <form onSubmit={createModule}>
            <label>Module Name </label>
            <input
              placeholder="Enter Module Name"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <div className="text-center">
              <button disabled={isModuleLoading}>
                {isModuleLoading ? <SmallLoader color={"#fff"} /> : "Done"}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModuleModal;
