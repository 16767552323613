import React from "react";
import VideoComponent from "../layout/VideoComponent";
import { useNavigate } from "react-router-dom";

const DUMMY_DATA = [
  {
    title: "Lecture 1 ",
    description: `Geometry is a branch of mathematics that deals with the study of shapes, sizes, properties of space, and the relationships between various elements. The word "geometry" itself comes from the Greek words "geo," meaning Earth, and "metron,"`,
    date: "31 Jan 2023",
  },
  {
    title: "Lecture 2 ",
    description: `Geometry is a branch of mathematics that deals with the study of shapes, sizes, properties of space, and the relationships between various elements. The word "geometry" itself comes from the Greek words "geo," meaning Earth, and "metron,"`,
    date: "15 Feb 2023",
  },
  {
    title: "Lecture 3 ",
    description: `Geometry is a branch of mathematics that deals with the study of shapes, sizes, properties of space, and the relationships between various elements. The word "geometry" itself comes from the Greek words "geo," meaning Earth, and "metron,"`,
    date: "02 Mar 2023",
  },
];

const ModuleVideoview = ({ currentVideo, allVideos, id }) => {
  return (
    <div className="video_view_container">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-12">
          <div className="video_view_left scrollbar-template">
            <VideoComponent
              imgSrc={currentVideo.thumbnail}
              videoUrl={currentVideo.videoUrl}
              Height={"60vh"}
              Width={"100%"}
              iconSize={"82px"}
              iconpos={{
                top: window.screen.width < 567 ? "35%" : "41%",
                right: window.screen.width < 567 ? "34%" : "46%",
              }}
            />
            <h2>{currentVideo.title} </h2>
            <p>{currentVideo.content}</p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-12">
          <div className="video_view_right scrollbar-template">
            {allVideos.map((data, i) => {
              return (
                <ModuleVideoItems
                  data={data}
                  i={i}
                  currentVideo={currentVideo}
                  id={id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleVideoview;

const ModuleVideoItems = ({ data, i, currentVideo, id }) => {
  const shorttxt = data.content.split("").slice(0, 25);
  shorttxt.push("...");
  const navigate = useNavigate();
  return (
    <div
      className={`video_view_right_item cursor-pointer ${
        currentVideo._id === data._id ? "active" : ""
      }`}
      key={i}
      onClick={() => navigate(`/batch/module/video/view/${id}/${data._id}`)}
    >
      <VideoComponent
        imgSrc={data.thumbnail}
        videoUrl={data.videoUrl}
        Height={"95px"}
        Width={window.screen.width < 567 ? "100%" : "144px"}
        iconSize={"42px"}
        iconpos={{
          top: window.screen.width < 567 ? "42%" : "28%",
          right: window.screen.width < 567 ? "43%" : "35%",
        }}
      />
      <div className="video_view_right_item_desc">
        <h2>{data.title}</h2>
        <p>{shorttxt.join("")}</p>
      </div>
    </div>
  );
};
