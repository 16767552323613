import React, { useEffect, useRef, useState } from "react";
import { images, useOutsideClick } from "../../actions/customFn";
import { HiDotsHorizontal } from "react-icons/hi";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { InfoPurple } from "../../asset/icons/Icon";
import BatchDetailModal from "./BatchDetailModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setfilterBatches } from "../../slice/batchSlice";

const BatchList = ({ allBatches, pathname }) => {
  const filterBatches = useSelector((state) => state.batchData.filterBatches);
  const Dummy_DATA = [
    {
      heading: "Batch A name Zero",
      start: "started at 15 jan 2023",
      total: "5 modules 56 students",
      image: images["batch_img_eg.png"],
    },

    {
      heading: "BATCHMACHINELEARNING",
      start: "started at 15 jan 2023",
      total: "5 modules 56 students",
      image: images["batch_img_eg.png"],
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (allBatches.length > 0) {
      dispatch(setfilterBatches(allBatches));
    }
  }, [allBatches]);

  return (
    <div className="batchlist_container ">
      <div className="batchlist_buttons">
        <button
          onClick={() => navigate("/batch")}
          className={pathname === "/batch" ? "active" : ""}
        >
          All
        </button>
        <button
          onClick={() => navigate("/batch-active")}
          className={pathname === "/batch-active" ? "active" : ""}
        >
          Active
        </button>
        <button
          onClick={() => navigate("/batch-inactive")}
          className={pathname === "/batch-inactive" ? "active" : ""}
        >
          Inactive
        </button>
      </div>

      <div className="batchlist_cards scrollbar-template ">
        {filterBatches.length > 0 ? (
          filterBatches.map((data, i) => {
            return <BatchItem data={data} i={i} />;
          })
        ) : (
          <h4 className="batchitem_notfound">no batches found</h4>
        )}
      </div>
    </div>
  );
};

export default BatchList;

const BatchItem = ({ data, i }) => {
  const [modalShow, setShowModal] = useState(false);
  const [scheduleStr, setScheduleStr] = useState("No Schedule");
  const optionRef = useRef(null);
  // const searchRef = useRef("");
  const [isOpen, setIsOpen] = useState(false);
  const getScheduleStr = () => {
    let str = "";
    if (data?.schedule?.length > 0) {
      for (let i = 0; i < data.schedule.length; i++) {
        str += data.schedule[i].day + " ";
      }
      setScheduleStr(str);
    }
  };

  useEffect(() => {
    getScheduleStr();
  }, [data.schedule]);

  useOutsideClick(optionRef, setIsOpen);

  return (
    <div className="batchlist_card_items" key={i}>
      <div className="batchlist_card_items_left">
        <img alt="" src={data.courseInfo.image} />
        <div className="batchlist_card_items_details">
          <h3>{data?.custom_name || data.batch_name}</h3>
          <h4>Started At: {moment(data.batch_start).format("DD MMM YYYY")}</h4>
          <h5>
            Schedule: {data.schedule_str || scheduleStr || "No Schedule Found!"}
          </h5>
        </div>
      </div>
      <div className="batchlist_card_items_right">
        <div className="batchlist_card_item_options">
          <HiDotsHorizontal onClick={() => setIsOpen(true)} />
          {isOpen && (
            <div className="batchlist_card_option_div" ref={optionRef}>
              <div
                className="batchlist_card_info"
                onClick={() => {
                  setShowModal(true);
                  setIsOpen(false);
                }}
              >
                <InfoPurple />
                <span>More Information</span>
              </div>
            </div>
          )}
        </div>
        <div className="batchlist_card_item_view">
          <NavLink to={`/batch/module/${data._id}`}>
            <span>View</span>
            <FaArrowRightLong />
          </NavLink>
        </div>
      </div>
      <BatchDetailModal
        data={data}
        modalShow={modalShow}
        setShowModal={setShowModal}
        schedule_str={data.schedule_str ? data.schedule_str : ""}
        programName={data.custom_name || ""}
      />
    </div>
  );
};
