import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "../../api/axios";

const StudentReportModal = ({ modalShow, setShowModal, ID, selectedBatch }) => {
  const [assignmentDetails, setAssignmentDetails] = useState([]);

  const getAllAssignment = () => {
    const url = `batch/student-assignment-detail`;
    const paramObj = {
      batchId: selectedBatch,
      userId: ID,
    };

    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        setAssignmentDetails(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllAssignment();
  }, [ID, selectedBatch]);

  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body>
        <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="comment-modal-body">
          <div className="comment-modal-body-heading">
            <h2>Report </h2>
          </div>
          <div className="student-report-body-div scrollbar-template">

          {assignmentDetails?.map((data, i) => {
            return (
              <div className="student-report-body-details">
                <h4>
                  {i + 1}. {data.batchId.batch_name}
                </h4>
                <h5>
                  {data.title} (
                  {data.projectSubmission?.length > 0
                    ? data.projectSubmission[0].rating + "/10"
                    : 0 + "/10"}
                  )
                </h5>
              </div>
            );
          })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StudentReportModal;
