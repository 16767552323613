import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { asyncsendCertificate } from "../../actions/batchApi";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../api/axios";
import SmallLoader from "../../utils/SmallLoader";

const CertificateModal = ({
  setShowModal,
  modalShow,
  data,
  courseInfo,
  batchId,
  setIsUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [isInternship, setIsInternship] = useState(false);
  const [isLor, setIsLor] = useState(false);
  const [date, setDate] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    let endDate = new Date(courseInfo.startDate).setDate(
      new Date(courseInfo.startDate).getDate() + 90
    );
    const formatStartdate = getCertificateFormatDate(courseInfo.startDate);
    const formatenddate = getCertificateFormatDate(endDate);
    setDate({
      start: formatStartdate,
      end: formatenddate,
    });
  }, [data]);

  const getCertificateFormatDate = (date) => {
    const newDate = new Date(date);
    let dayandmonth = newDate.toLocaleDateString("en-GB", {
      month: "long",
      day: "2-digit",
    });
    const formatDate =
      dayandmonth.split(" ").reverse().join(" ").toString() +
      ", " +
      newDate.getFullYear();
    return formatDate;
  };

  const sendCertificate = () => {
    setIsLoading(true);
    const formData = {
      email: data.email,
      program: courseInfo.program,
      name: data.username,
      internCode: data.intern_code,
      gender: data.gender,
      startdate: date.start,
      enddate: date.end,
      isInternship,
      isLor,
    };

    dispatch(
      asyncsendCertificate(
        formData,
        setIsLoading,
        null,
        setIsUpdate,
        setShowModal
      )
    );
    addCertificateData();
  };

  const addCertificateData = () => {
    const url = "/batch/certificate-record";
    const formdata = {
      batchId,
      userId: data._id,
      isLor,
      isInternship,
      username: data.username,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .post(url, formdata, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log("error");
        notifyDanger(err.response.message || "some error occured");
      });
  };

  return (
    <Modal
      show={modalShow}
      className="bootstrap-modal-custom"
      onHide={() => {
        !isLoading && setShowModal(false);
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="certificate-modal-body">
          {!isLoading && (
            <AiOutlineClose
              className="modal-close-btn"
              onClick={() => {
                setShowModal(false);
              }}
            />
          )}

          <h2>Student information</h2>
          <div className="row">
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>Name</label>
                <input
                  placeholder="Enter student Name"
                  value={data.username}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>Gender</label>
                <input
                  placeholder="Enter student Gender"
                  value={data.gender}
                  disabled
                />
              </div>
            </div>
            <div className="col-12">
              <div className="certificate-modal-inp">
                <label>Email</label>
                <input
                  placeholder="Enter your Email"
                  value={data.email}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>Program</label>
                <input
                  placeholder="Enter your Program"
                  value={courseInfo.program}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>Intern Code</label>
                <input
                  placeholder="Enter your Intern Code"
                  value={data.intern_code}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>Start Date</label>
                <input
                  placeholder="Enter your Start Date"
                  value={date.start}
                  disabled
                />
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-inp">
                <label>End Date</label>
                <input
                  placeholder="Enter your End Date"
                  value={date.end}
                  disabled
                />
              </div>
            </div>
            <div className="col-12">
              <div className="certificate-modal-radio-text">Certificate</div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-radio">
                <input
                  type="checkbox"
                  name="radio"
                  value={isInternship}
                  checked={isInternship}
                  onChange={() => setIsInternship((p) => !p)}
                />
                <label className="radio-container">Internship</label>
              </div>
            </div>
            <div className="col-6">
              <div className="certificate-modal-radio">
                <input
                  type="checkbox"
                  value={isLor}
                  checked={isLor}
                  onChange={() => setIsLor((p) => !p)}
                />
                <label className="radio-container">
                  Letter Of Recommendation
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="certificate-modal-submit">
                <button disabled={isLoading} onClick={sendCertificate}>
                  {isLoading ? <SmallLoader color={"#fff"} /> : "Send"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CertificateModal;
