import React, { useContext, useEffect, useRef, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdEditDocument } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import { MdOutlineAdd } from "react-icons/md";
import {
  confirmToast,
  getUtcTime,
  notifyDanger,
  notifySuccess,
  useOutsideClick,
} from "../../actions/customFn";
import axios from "../../api/axios";
import { BatchContext } from "../../pages/Batch";
import { ModuleContext } from "../../pages/Module";
import moment from "moment";
import ScheduleModal from "../home/ScheduleModal";
import { useSelector } from "react-redux";
import Loader from "../../utils/Loader";

const DUMMY_DATA = [
  {
    text: "Monday 4pm (UTC)",
  },
  {
    text: "Wednesday 4pm (UTC)",
  },
  {
    text: "Friday 4pm (UTC)",
  },
];

const ModuleSchedule = ({ scheduleArr, id }) => {
  const [modalShow, setShowModal] = useState(false);
  const { setIsUpdate, batch, isUpdate } = useContext(ModuleContext);
  const [isLoading, setIsLoading] = useState();

  const profileData = useSelector((state) => state.profileData.userData);
  const [allBatches, setAllBatches] = useState([]);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      setAllBatches(profileData.batches);
    }
  }, [profileData]);

  // const deleteSchedule = (deleteID) => {
  //   const newArr = scheduleArr.filter((val, i) => {
  //     return i !== deleteID;
  //   });
  //   const formData = {
  //     id,
  //     schedule: newArr,
  //   };
  //   const url = "/batch/add-schedule";
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
  //     },
  //   };
  //   axios
  //     .put(url, formData, config)
  //     .then((res) => {
  //       const { message } = res.data;
  //       notifySuccess(message);
  //       setIsUpdate((p) => !p);
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       notifyDanger(err.response.message || "Some error Occured");
  //       setIsUpdate((p) => !p);
  //     });
  // };

  const deleteSchedule = (deleteID) => {
    setIsLoading(true);
    const url = `/batch/upload-video/${deleteID}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess("schedule deleted!");
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err.response);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  const [scheduleList, setScheduleList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const url = `/batch/get-upcoming-schedule/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setScheduleList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        notifyDanger(err?.response?.data?.message || "Some error Occured");
        setScheduleList([]);
        setIsLoading(false);
      });
  }, [id, isUpdate]);


  console.log(scheduleList ,   "schedule");

  return (
    <div className="module-schedule-container">
      {isLoading ? (
        <Loader Height={"45vh"} />
      ) : (
        <div className="module-schedule-list scrollbar-template">
          {scheduleList.length > 0 ? (
            // scheduleList.map((module, i) => {
            //   return module.lessons.map((lesson, index) => {
            //     return (
            //       <ModuleScheduleItems
            //         data={lesson}
            //         index={i}
            //         // moduleTitle
            //         // moduleId
            //         moduleName={lesson.module.moduleTitle}
            //         moduleId={lesson.module.moduleId}
            //         deleteSchedule={deleteSchedule}
            //         scheduleArr={scheduleArr}
            //         id={id}
            //         allBatches={allBatches}
            //         batchId={batch._id}
            //         setIsUpdate={setIsUpdate}
            //       />
            //     );
            //   });
            // })
            scheduleList.map((lesson, i) => {
              return (
                <ModuleScheduleItems
                  data={lesson}
                  index={i}
                  // moduleTitle
                  // moduleId
                  moduleName={lesson.module.moduleTitle}
                  moduleId={lesson.module.moduleId}
                  deleteSchedule={deleteSchedule}
                  scheduleArr={scheduleArr}
                  id={id}
                  allBatches={allBatches}
                  batchId={batch._id}
                  setIsUpdate={setIsUpdate}
                />
              );
            })
          ) : (
            <h4 className="text-black text-center mt-4">No Schedule Found!</h4>
          )}
          {/* {scheduleArr.length > 0 &&
          scheduleArr.map((data, i) => {
            return (
              <ModuleScheduleItems
                data={data}
                i={i}
                deleteSchedule={deleteSchedule}
                scheduleArr={scheduleArr}
                id={id}
              />
            );
          })} */}
        </div>
      )}
      {/* <div className="module-schedule-create">
        <button onClick={() => setShowModal(true)}>
          <MdOutlineAdd />
          <span> create Schedule</span>
        </button>
      </div> */}
      {/* <ScheduleModal
        modalShow={modalShow}
        setShowModal={setShowModal}
        scheduleArr={scheduleArr}
        id={id}
      /> */}
    </div>
  );
};

export default ModuleSchedule;

const ModuleScheduleItems = ({
  data,
  i,
  deleteSchedule,
  scheduleArr,
  id,
  moduleName,
  allBatches,
  moduleId,
  batchId,
  setIsUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const scheduleRef = useRef(null);
  const [modalShow, setShowModal] = useState(false);
  useOutsideClick(scheduleRef, setIsOpen);

  return (
    <div className="module-schedule-item" key={i}>
      <div className="module-schedule-item-heading">
        <h3>{data.title}</h3>
        <p>{data.content}</p>
        <div className="module-schedule-item-info">
          <span>{moduleName}</span>
          <h6>{getUtcTime(data.schedule_time)}</h6>
        </div>
      </div>
      <div className="module-schedule-item-options">
        <HiDotsHorizontal onClick={() => setIsOpen((p) => !p)} />
        {isOpen && (
          <div className="module-schedule-options-div" ref={scheduleRef}>
            <div
              className="module-schedule-options-item"
              onClick={() => setShowModal(true)}
            >
              <MdEditDocument />
              <span>Edit</span>
            </div>
            <div
              className="module-schedule-options-item"
              onClick={() =>
                confirmToast("Are you want to delete this schedule?", () => {
                  deleteSchedule(data._id);
                })
              }
            >
              <RiDeleteBinFill />
              <span>delete</span>
            </div>
          </div>
        )}
      </div>
      {/* <ScheduleModal
        modalShow={modalShow}
        setShowModal={setShowModal}
        scheduleArr={scheduleArr}
        id={id}
        isEdit={true}
        data={data}
        currentIndex={i}
      /> */}

      {modalShow && (
        <ScheduleModal
          modalShow={modalShow}
          allBatches={allBatches}
          setShowModal={setShowModal}
          isEdit={true}
          data={data}
          module_id={moduleId}
          batch_id={batchId}
          updateData={setIsUpdate}
        />
      )}
    </div>
  );
};
