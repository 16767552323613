import React, { createContext, useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import BatchHeader from "../components/batch/BatchHeader";
import BatchList from "../components/batch/BatchList";
import { useDispatch, useSelector } from "react-redux";
import { asyncProfileData } from "../actions/loginAction";
import { useLocation } from "react-router-dom";

export const BatchContext = createContext();

const Batch = () => {
  const profileData = useSelector((state) => state.profileData.userData);
  const [allBatches, setAllBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      let filterbatch = profileData.batches;
      if (pathname === "/batch-active") {
        filterbatch = profileData.batches.filter((data) => {
          return data.active === true;
        });
      }
      if (pathname === "/batch-inactive") {
        filterbatch = profileData.batches.filter((data) => {
          return data.active === false;
        });
      }
      setAllBatches(filterbatch);
    }
  }, [profileData, pathname]);

  useEffect(() => {
    dispatch(asyncProfileData());
  }, [isUpdate]);

  return (
    <BatchContext.Provider value={{ isUpdate, setIsUpdate }}>
      <LayoutWrapper>
        <Header />
        <BatchHeader allBatches={allBatches} />
        <BatchList
          pathname={pathname}
          allBatches={allBatches}
          isLoading={isLoading}
        />
      </LayoutWrapper>
    </BatchContext.Provider>
  );
};

export default Batch;
