import React, { useState } from "react";
import { images } from "../../actions/customFn";
import { MdOutlineAdd } from "react-icons/md";
import CreateModuleModal from "../module/createModuleModal";
import UpdateModuleModal from "../module/UpdateModuleModal";

const ModuleVideoHeader = ({
  setShowModal,
  Component,
  btnTxt,
  moduleTitle,
  setIsUpdate
}) => {
  const [updatemoduleShow, setUpdateModuleModal] = useState(false);
  return (
    <div className="module_video_header_container">
      <div className="video_header_editmodule">
        <h3>{moduleTitle}</h3>
        <button onClick={()=>setUpdateModuleModal(true)}>
          <img alt="" src={images["edit-pencil-img.png"]} />
        </button>
      </div>
      <div className="video_header_addvideo">
        <button onClick={() => setShowModal(true)}>
          <MdOutlineAdd />
          <span>{btnTxt}</span>
        </button>
      </div>
      {Component}
      <UpdateModuleModal
        modalShow={updatemoduleShow}
        setShowModal={setUpdateModuleModal}
        moduleTitle={moduleTitle}
        setIsUpdate={setIsUpdate}
      />
    </div>
  );
};

export default ModuleVideoHeader;
