import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import SmallLoader from "../../utils/SmallLoader";
import { useDispatch } from "react-redux";
import { asyncUpdateModuleTitle } from "../../actions/batchApi";

const UpdateModuleModal = ({
  moduleTitle,
  modalShow,
  setShowModal,
  setIsUpdate,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [isModuleLoading, setModuleIsLoading] = useState(false);

  const initialState = () => {
    setShowModal(false);
    setTitle("");
    setModuleIsLoading(false);
  };

  const updateModule = (e) => {
    e.preventDefault();
    setModuleIsLoading(true);
    const data = {
      id,
      title,
    };
    dispatch(asyncUpdateModuleTitle(data, setIsUpdate, initialState));
  };

  useEffect(() => {
    setTitle(moduleTitle);
  }, [moduleTitle]);

  return (
    <Modal
      className="create-module-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Body>
        <div className="create-module-modal-body">
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => setShowModal(false)}
          />
          <h2>Update Module</h2>
          <form onSubmit={updateModule}>
            <label>Module Name </label>
            <input
              placeholder="Enter Module Name"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <div className="text-center">
              <button disabled={isModuleLoading}>
                {isModuleLoading ? <SmallLoader color={"#fff"} /> : "Done"}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModuleModal;
