import React, { useContext, useState } from "react";
import { MdAssignment } from "react-icons/md";
import { FaCommentAlt } from "react-icons/fa";
import { MdModeComment } from "react-icons/md";
import SubmissionModal from "./SubmissionModal";
import CommentModal from "./CommentModal";
import moment from "moment";
import { AssignmentViewContext } from "../../pages/AssignmentView";

const DUMMY_DATA = [
  {
    phone: "+91 123456789",
    student_name: "Raghav Sharma",
    rating: 5,
    email: "raghav@example.com",
    assigned_date: "30/03/2024",
  },
  {
    phone: "+91 123456789",
    student_name: "Test User",
    rating: 3,
    email: "test@example.com",
    assigned_date: "01/04/2024",
  },
  {
    phone: "+91 123456789",
    student_name: "Test User 2",
    rating: 9,
    email: "test2@example.com",
    assigned_date: "10/04/2024",
  },
  {
    phone: "+91 123456789",
    student_name: "TEST USER 4 ",
    rating: 10,
    email: "test4@example.com",
    assigned_date: "30/03/2024",
  },
];
const AssignmentviewTable = ({ assignment }) => {
  return (
    <div className="allassignment_table_container">
      <div className="assignment_view_table_div scrollbar-template">
        <table className="table-template-style allassignment_table_tag">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Student Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Rating</th>
              <th>Submitted Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {assignment?.projectSubmission?.length > 0 &&
              assignment?.projectSubmission.map((data, i) => {
                return (
                  <AssignmentTableItem
                    data={data}
                    i={i}
                    work_type={assignment.work_type}
                    title={assignment.title}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssignmentviewTable;

const AssignmentTableItem = ({ data, i, work_type , title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmissionOpen, setIsSubmissionOpen] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const assignedDate = moment(data.date).format("DD MMM YYYY");
  const { setIsLoading, setIsUpdate } = useContext(AssignmentViewContext);

  return (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>{data?.user?.username || "Not Found"}</td>
      <td>{data?.user?.email || "Not Found"}</td>
      <td>{data?.user?.phone_no || "Not Found"}</td>
      <td>{data.rating}/10</td>
      <td>{assignedDate}</td>
      <td>
        <div className="assignment_table_btns assignmentview_table_btns">
          <MdAssignment onClick={() => setIsSubmissionOpen(true)} />
          <MdModeComment onClick={() => setIsCommentOpen(true)} />
        </div>
      </td>
      <SubmissionModal
        modalShow={isSubmissionOpen}
        setShowModal={setIsSubmissionOpen}
        data={data}
        setIsLoading={setIsLoading}
        setIsUpdate={setIsUpdate}
        work_type={work_type}
        title={title}
      />
      <CommentModal
        modalShow={isCommentOpen}
        setShowModal={setIsCommentOpen}
        data={data}
        setIsLoading={setIsLoading}
        setIsUpdate={setIsUpdate}
        work_type={work_type}
        title={title}

      />
    </tr>
  );
};
