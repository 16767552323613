import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ModuleViewHeader from "../components/moduleview/ModuleViewHeader";
import ModuleViewCards from "../components/moduleview/ModuleViewCards";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetModuleDetails } from "../actions/batchApi";
const ModuleView = () => {
  const [moduleTitle, setModuleTitle] = useState("");
  const [lessonNum, setLessonNum] = useState(0);
  const [resourceNum, setResourceNum] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const module_details = useSelector((state) => state.batchData.module_details);

  useEffect(() => {
    dispatch(asyncGetModuleDetails(id));
  }, [id]);


  useEffect(() => {
    if (module_details && Object.keys(module_details).length > 0) {
      setModuleTitle(module_details.title);
      setLessonNum(module_details?.lessons?.length || 0);
      setResourceNum(
        module_details?.refrence_links?.length +
          module_details?.study_material?.length || 0
      );
    }
  }, [module_details]);

  return (
    <LayoutWrapper>
      <Header />
      <ModuleViewHeader title={moduleTitle} />
      <ModuleViewCards lessonNum={lessonNum} resourceNum={resourceNum} id={id} />
    </LayoutWrapper>
  );
};

export default ModuleView;
