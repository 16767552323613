import React from "react";
import { RiDeleteBinFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { confirmToast } from "../../actions/customFn";
import { asyncDeleteStudyMaterial } from "../../actions/batchApi";

const ResourceList = ({
  refrenceList,
  resourceList,
  setIsUpdate,
  setIsLoading,
}) => {
  const DUMMY_ARR = [
    {
      title: "dsa_slides.ppt",
    },
    {
      title: "algo_ref.pdf",
    },
    {
      title: "toast.xl",
    },
    {
      title: "toast.xl",
    },
  ];
  return (
    <div className="resource_list_container ">
      <div className="row scrollbar-template">
        <div className="col-12 mb-3">
          <h4>Resource List </h4>
        </div>
        {resourceList?.length > 0 &&
          resourceList.map((data, i) => {
            return (
              <ResourceItems
                data={data}
                index={i}
                setIsUpdate={setIsUpdate}
                setIsLoading={setIsLoading}
              />
            );
          })}

        <div className="col-12 mb-3">
          <h4>Refrence List </h4>
        </div>
        {refrenceList?.length > 0 &&
          refrenceList.map((data, i) => {
            return (
              <RefrenceList
                data={data}
                index={i}
                setIsUpdate={setIsUpdate}
                setIsLoading={setIsLoading}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ResourceList;

const ResourceItems = ({ data, index, setIsUpdate, setIsLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const getName = () => {
    if (data.name) {
      return `${data.name}.${data.url.split(".").pop()}`;
    } else {
      const fileIndex = data.url.lastIndexOf("/") + 1;
      const url = data.url.substr(fileIndex);
      const shorttxt = url.split("").slice(0, 14);
      shorttxt.push("...");
      return `${shorttxt.join("")}.${data.url.split(".").pop()}`;
    }
  };

  const deleteResource = () => {
    const formData = {
      id,
      resource: data.url,
    };
    confirmToast("Are you want to delete this resource?", () => {
      setIsLoading(true);
      dispatch(asyncDeleteStudyMaterial(formData, setIsUpdate));
    });
  };
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-12" key={index}>
      <div className="resource_list_item">
        <h4>{getName()}</h4>
        <div className="resource_list_btns">
          <button onClick={() => window.open(data.url)}>View</button>
          <RiDeleteBinFill onClick={deleteResource} />
        </div>
      </div>
    </div>
  );
};

const RefrenceList = ({ data, index, setIsUpdate, setIsLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const defaultUrl = () => {
    const urlArr = Object.values(data);
    urlArr.pop();
    return urlArr.join("");
  };
  const deleteRefrence = () => {
    const formData = {
      id,
    };
    if (data.name) {
      formData.refrenceID = data._id;
    } else {
      formData.refrence = defaultUrl();
    }

    confirmToast("Are you want to delete this refrence?", () => {
      setIsLoading(true);
      dispatch(asyncDeleteStudyMaterial(formData, setIsUpdate));
    });
  };
  return (
    <div className="col-xl-4 col-lg-4 clo-md-6 col-12" key={index}>
      <div className="resource_list_item">
        <h4>{data?.name || `file${index + 1}`}</h4>
        <div className="resource_list_btns">
          <button onClick={() => window.open(data.url || defaultUrl())}>
            View
          </button>
          <RiDeleteBinFill onClick={deleteRefrence} />
        </div>
      </div>
    </div>
  );
};
