import React from "react";
import Sidebar from "./Sidebar";

const LayoutWrapper = ({children}) => {
  return (
    <div className="row">
      <div className="col-2 p-0">
        <Sidebar />
      </div>
      <div className="col-xl-10 col-12 p-0">
        {children}
      </div>
    </div>
  );
};

export default LayoutWrapper;
