import React, { createContext, useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ModuleHeader from "../components/module/ModuleHeader";
import ModuleCards from "../components/module/ModuleCards";
import { useLocation, useParams } from "react-router-dom";
import ModuleStudent from "../components/module/ModuleStudent";
import ModuleSchedule from "../components/module/ModuleSchedule";
import axios from "../api/axios";
import Loader from "../utils/Loader";

export const ModuleContext = createContext();

const Module = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [batch, setBatch] = useState({
    name: "",
    img: "",
  });
  const [scheduleArr, setScheduleArr] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    const url = `/batch/get-batch-byId/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setModuleList(data.modules);
        setBatch({
          name:data?.custom_name || data.batch_name,
          img: data.courseInfo.image,
          _id:data._id
        });
        setScheduleArr(data.schedule ? data.schedule : []);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response.data);
      });
  }, [isUpdate]);

  return (
    <ModuleContext.Provider
      value={{ isUpdate, setIsUpdate, isLoading, setIsLoading  , batch}}
    >
      <LayoutWrapper>
        <Header />
        <ModuleHeader batch={batch} id={id} />
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <>
            {pathname.includes("/module") && (
              <ModuleCards moduleList={moduleList} id={id} />
            )}
            {pathname.includes("/students") && <ModuleStudent id={id} />}
            {pathname.includes("/schedule") && (
              <ModuleSchedule scheduleArr={scheduleArr} id={id} />
            )}
          </>
        )}
      </LayoutWrapper>
    </ModuleContext.Provider>
  );
};

export default Module;
