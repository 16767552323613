import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ModuleViewHeader from "../components/moduleview/ModuleViewHeader";
import ModuleVideoHeader from "../components/moduleVideos/ModuleVideoHeader";
import UploadVideoModal from "../components/moduleVideos/videoupload/UploadVideoModal";
import ResourceModal from "../components/moduleview/ResourceModal";
import ResourceList from "../components/moduleview/ResourceList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetModuleDetails } from "../actions/batchApi";
import Loader from "../utils/Loader";

const ModuleResources = () => {
  const [modalShow, setShowModal] = useState(false);
  const [moduleTitle, setModuleTitle] = useState("");
  const [allVideos, setAllVideos] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refrenceList, setRefrenceList] = useState([]);
  const [resourceList, setResourceList] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const module_details = useSelector((state) => state.batchData.module_details);

  useEffect(() => {
    setIsLoading(true);
    dispatch(asyncGetModuleDetails(id, setIsLoading));
  }, [id, isUpdate]);

  useEffect(() => {
    if (module_details && Object.keys(module_details).length > 0) {
      setModuleTitle(module_details.title);
      setResourceList(module_details.study_material);
      setRefrenceList(module_details.refrence_links);
    }
  }, [module_details]);

  return (
    <LayoutWrapper>
      <Header />
      <ModuleViewHeader title={"Study Material"} />
      <ModuleVideoHeader
        Component={
          <ResourceModal
            modalShow={modalShow}
            setShowModal={setShowModal}
            setIsUpdate={setIsUpdate}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            moduleTitle={moduleTitle}
          />
        }
        setShowModal={setShowModal}
        btnTxt={"Add Study Material"}
        moduleTitle={moduleTitle}
      />
      {isLoading ? (
        <Loader Height={"45vh"}/>
      ) : (
        <ResourceList
          refrenceList={refrenceList}
          resourceList={resourceList}
          setIsUpdate={setIsUpdate}
          setIsLoading={setIsLoading}
        />
      )}
    </LayoutWrapper>
  );
};

export default ModuleResources;
