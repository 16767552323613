import React from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import AssignmentHeader from "../components/assignment/AssignmentHeader";
import Header from "../components/layout/Header";
import AllAssignmentTable from "../components/assignment/AllAssignmentTable";

const Assignment = () => {
  return (
    <LayoutWrapper>
      <Header />
      <AssignmentHeader title={"Work"} />
      <AllAssignmentTable />
    </LayoutWrapper>
  );
};

export default Assignment;
