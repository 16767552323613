import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import moment from "moment";
import axios from "../../api/axios";
import SmallLoader from "../../utils/SmallLoader";
import { BatchContext } from "../../pages/Batch";
import { RiDeleteBinFill } from "react-icons/ri";

const BatchDetailModal = ({
  modalShow,
  setShowModal,
  data,
  schedule_str,
  programName,
}) => {
  const [meetlink, setMeetLink] = useState("");
  const [active, setActive] = useState(false);
  const [customName, setCustomName] = useState("");
  const [btnTxt, setBtnTxt] = useState("Update");
  const [scheduleInp, setScheduleInp] = useState("");
  const { setIsUpdate } = useContext(BatchContext);
  const [isScheduleLoading, setIsScheduleLoading] = useState(false);

  useEffect(() => {
    if (data.active) {
      setActive(data.active);
    } else {
      setActive(false);
    }
    if (data.meetlink) {
      setMeetLink(data.meetlink);
    }
  }, [data]);

  const handleMeetlink = () => {
    setBtnTxt(<SmallLoader color={"#fff"} />);
    const formData = {
      id: data._id,
      meetlink,
      active,
      schedule_str: scheduleInp,
      custom_name: customName,
    };
    const url = "/batch/add-meet";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setBtnTxt("Update");
        setShowModal(false);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err.response);
        setBtnTxt("Update");
        notifyDanger(err.response.message || "Some error Occured");
      });
  };
  useEffect(() => {
    if (schedule_str) {
      setScheduleInp(schedule_str);
    }
  }, [schedule_str]);

  useEffect(() => {
    if (programName) {
      setCustomName(programName);
    }
  }, [programName]);

  return (
    <Modal
      centered
      className="batch-details-modal bootstrap-modal-custom"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body>
        <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="batch-details-modal-body">
          <div className="batch-details-modal-name">
            <img alt="" src={data.courseInfo.image} />
            <span>{data.batch_name}</span>
          </div>
          <div className="batch-details-modal-info">
            <div className="batch-modal-info-title">
              <span>Batch Name</span>
              <span>start date</span>
              <span>Duration</span>
              <span>Strength</span>
              <span>Workspace</span>
            </div>
            <div className="batch-modal-info-title">
              <span>:</span>
              <span>:</span>
              <span>:</span>
              <span>:</span>
              <span>:</span>
            </div>
            <div className="batch-modal-info-title">
              <span>{data.batch_name}</span>
              <span>{moment(data.batch_start).format("DD/MMM/YYYY")}</span>
              <span>3 Months</span>
              <span>{data.enroll_students.length} Students</span>
              <span>{data.assignment.length} Assignment/Project</span>
            </div>
          </div>
          <div className="batch-details-modal-meetlink">
            <label>Add/update meetlink</label>
            <input
              placeholder="Enter your meetlink"
              type="text"
              value={meetlink}
              onChange={(e) => setMeetLink(e.target.value)}
            />
            <label className="mt-3">Add Schedule Days</label>
            <div className="input-group ">
              <select
                name="day"
                onChange={(e) => setScheduleInp(e.target.value)}
                value={scheduleInp}
              >
                <option value={""}>Select Day </option>
                <option value={"Monday Wednesday Friday (MWF)"}>
                  Monday Wednesday Friday (MWF)
                </option>
                <option value={"Tuesday Thursday Friday (TTF)"}>
                  Tuesday Thursday Friday (TTF)
                </option>
                <option value={"Monday Tuesday Wednesday (MTW)"}>
                  Monday Tuesday Wednesday (MTW)
                </option>
              </select>
            </div>
            <div
              className="d-flex align-items-center mb-2"
              style={{ gap: "14px " }}
            >
              <div className="">
                <label className="mt-3">Custom Name</label>
                <input
                  placeholder="Enter custom name"
                  value={customName}
                  onChange={(e) => setCustomName(e.target.value)}
                />
              </div>
              <div>
                <label className="mt-3">Active/Inactive Batch</label>
                <div className="switch_inp d-flex justify-content-end">
                  <label class="switch m-0">
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={() => setActive((p) => !p)}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="text-center batch-modal-submit">
              <button
                onClick={() => handleMeetlink()}
                disabled={btnTxt !== "Update"}
              >
                {btnTxt}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BatchDetailModal;
