import axios from "../api/axios";
import { setBatchStudents, setModuleDetails } from "../slice/batchSlice";
import { notifyDanger, notifySuccess } from "./customFn";

export const asyncCreateModule = (
  data,
  setIsUpdate,
  setIsLoading,
  initialState
) => {
  return (dispatch) => {
    const url = "/batch/module-create";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setIsLoading(false);
        if (initialState) {
          initialState();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured");
        setIsUpdate((p) => !p);
      });
  };
};

export const asyncGetModuleDetails = (id, setIsLoading) => {
  return (dispatch) => {
    const url = `/batch/module-details/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setModuleDetails(data?.modules[0] || {}));
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  };
};

export const asyncUpdateModuleTitle = (data, setIsUpdate, initialState) => {
  return (dispatch) => {
    const url = "/batch/module-update-name";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        initialState();
      })
      .catch((err) => {
        console.log(err.response);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };
};

export const asyncDeleteStudyMaterial = (data, setIsUpdate) => {
  return (dispatch) => {
    const url = `batch/delete-study-files`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        console.log(err.response);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };
};

export const asyncUpdateBatchSession = (data, setIsUpdate) => {
  return (dispatch) => {
    const url = `/batch/update-live-session`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        setIsUpdate((p) => !p);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const asyncGetBatchStudents = (id) => {
  return (dispatch) => {
    const url = `batch/batch-students/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setBatchStudents(data?.enroll_students || []));
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "some error occured");
      });
  };
};

export const asyncsendCertificate = (
  formData,
  setIsLoading,
  initialState,
  setIsUpdate,
  setShowModal
) => {
  return (dispatch) => {
    const url = "/batch/send-certificate";

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);

        setTimeout(() => {
          setIsLoading(false);
          setShowModal(false);
        }, 5000);
        if (setIsUpdate) {
          setIsUpdate((p) => !p);
        }
        if (initialState) {
          initialState();
        }
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };
};
