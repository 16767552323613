import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import QnaAccordian from "../components/QnaComponents/QnaAccordian";
import { useSelector } from "react-redux";
import axios from "../api/axios";
import { useLocation } from "react-router-dom";
import Loader from "../utils/Loader";

const QnaList = () => {
  const [qnaList, setQnaList] = useState([]);
  const [moduleIds, setModuleIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [batchModuleList, setBatchModulelist] = useState({
    batch: [],
    modules: [],
  });

  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [selectSortBy, setSelectedSortBy] = useState("");
  const [batchName, setBatchName] = useState("");

  const { pathname } = useLocation();

  const profileData = useSelector((state) => state.profileData.userData);

  const [allBatches, setAllBatches] = useState([]);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      setAllBatches(profileData.batches);
    }
  }, [profileData]);

  useEffect(() => {
    setIsLoading(true);
    const url = `/batch/qna-list`;
    let paramObj = {
      idsArray: JSON.stringify(moduleIds),
      userId: profileData._id,
    };
    if (selectedModule !== "") {
      paramObj.module_name = selectedModule;
    }
    if (selectedBatch !== "") {
      paramObj.batch_name = selectedBatch;
    }
    if (selectSortBy !== "") {
      paramObj.sort_by = selectSortBy;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: paramObj,
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsLoading(false);
        setQnaList(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
    getBatchModuleList();
  }, [moduleIds, isUpdate, selectedBatch, selectedModule, selectSortBy]);

  const getBatchModuleList = () => {
    const url = `/batch/batch-module-list`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        idsArray: JSON.stringify(moduleIds),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setBatchModulelist({
          batch: data.batchArr,
          modules: data.moduleArr,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      let newArr = profileData.batches.map((data, i) => {
        return data.modules;
      });

      const batchArr = profileData.batches.map((data, i) => {
        return { batch_name: data.batch_name, modules: data.modules };
      });

      setBatchName(batchArr);
      const combineArr = [].concat(...newArr);
      setModuleIds(combineArr);
    }
  }, [profileData]);

  useEffect(() => {
    if (pathname === "/qna") {
      setSelectedSortBy("");
    }
    if (pathname === "/qna-answered") {
      setSelectedSortBy("answered");
    }
    if (pathname === "/qna-unanswered") {
      setSelectedSortBy("unanswered");
    }
  }, [pathname]);

  return (
    <LayoutWrapper>
      <Header />
      <QnaAccordian
        qnaList={qnaList}
        batchName={batchName}
        selectedBatch={selectedBatch}
        setSelectedBatch={setSelectedBatch}
        allBatches={allBatches}
        isLoading={isLoading}
      />
    </LayoutWrapper>
  );
};

export default QnaList;
